


















import { Component, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import PaginatedList from '@/components/PaginatedList.vue'
import gql from 'graphql-tag'
import md5 from 'md5'
import { User, PaginatedQueryResult } from '../../models'

@Component({
  components: {
    Loading,
    PaginatedList
  }
})
export default class Users extends Vue {
  transformResult (user : Partial<User>) {
    return {
      ...user,
      imgIcon: this.userAvatarURL(user),
      title: user.profile && user.profile.name || user.email,
      subtitle: user.email || user._id,
      href: {
        name: 'adminUser',
        params: {
          userId: user._id
        }
      }
    }
  }

  userAvatarURL (user : Partial<User>) {
    const mainEmail = user &&
      (user.email || user._id)
    || ''

    const hash = md5(mainEmail.toLowerCase().trim())
    return `https://www.gravatar.com/avatar/${hash}?d=mp`
  }
}
