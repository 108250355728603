




























import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import { CreateElement, RenderContext } from 'vue'
import fieldTypes from '.'

@Component({
  name: 'Field'
})
export default class Field extends Vue {
  @Prop({ type: String, required: true }) name !: string
  @Prop({ type: Object, required: true }) schema !: FormField
  @Prop({ type: Object, required: true }) environmentVariables !: Record<string, any>
  @Prop({ default: () => [] }) errorMessages !: string | string[]

  @Prop({ default: null }) value ?: any
  @Prop({ type: Boolean, default: false }) viewOnly !: boolean
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  get formField () {
    // @ts-ignore
    return fieldTypes[this.schema.fieldType || this.schema.type]
  }
}
