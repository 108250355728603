

































import { Component, Vue, Prop, Provide } from 'vue-property-decorator'
import { FormSchema } from '@/models'
import Loading from '@/components/Loading.vue'
import Field from '@/components/fields/Field.vue'

@Component({
  components: {
    Field,
    Loading
  }
})
export default class Fields extends Vue {
  /** Form Schema */
  @Prop({ type: Object, required: true }) schema !: FormSchema
  /** Disabled state */
  @Prop({ type: Boolean }) disabled !: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables !: Record<string, any>

  /** Values */
  @Prop({ type: Object, default: () => ({}) }) value !: Record<string, any>
  /** External Validation Errors */
  @Prop({ type: Object, default: () => ({}) }) validationErrors !: Record<string, string[]>

  /** Is the form valid? */
  @Prop({ type: Boolean, default: false }) valid !: boolean

  /** Force Mobile Sizes */
  @Prop({ type: Boolean, default: false }) forceMobile !: boolean

  /** Enable negative margins */
  @Prop({ type: Boolean, default: false }) gutters !: boolean

  /** Current form value (to be accessed by child fields) */
  @Provide() fieldValues () {
    return this.value
  }

  mounted () {
    this.validate()
  }

  get formValid () {
    return this.valid
  }

  set formValid (v : boolean) {
    this.$emit('update:valid', v)
  }

  handleInput (fieldName : string, value : any) {
    if (this.validationErrors[fieldName]) delete this.validationErrors[fieldName]
    this.$emit('input', { ...this.value, [fieldName]: value })
  }

  validate () {
    return this.$refs.form && (this.$refs.form as any).validate()
  }

  resetValidation () {
    return this.$refs.form && (this.$refs.form as any).resetValidation()
  }

  reset () {
    return this.$refs.form && (this.$refs.form as any).reset()
  }
}
