














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import numeral from '@/plugins/numeral'


interface RangeFieldOptions {
  showSliderView ?: string,
  minNumberRange ?: number,
  maxNumberRange ?: number
}

@Component({})
export default class RangeField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  /** Validation Rules */

  get valueModel() {
    var fromValue = this.value && this.value.split('::')[0] || 0
    var toValue = this.value && this.value.split('::')[1] || 0
    return [fromValue, toValue]
  }

  set valueModel(value : any) {
    this.$emit('input', `${value[0] || ''}::${value[1] || ''}`)
  }

  get validationRules () {
    const rules = []
    // Required validation
    rules.push((v ?: string[]) => {
      return ( typeof (numeral(v![0]).value()) == 'number' && typeof (numeral(v![1]).value()) == 'number'  || 'Valores deben ser numérico.')
    })
    return rules
  }
  
  /** Additional field options */
  get fieldOptions () : RangeFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get fromRange() {
    return this.value && this.value.split('::')[0] || null
  }

  set fromRange(value : string | null) {
    this.$emit('input', `${value || ''}::${this.toRange || ''}`)
  }

  get toRange() {
    return this.value && this.value.split('::')[1] || null
  }

  set toRange(value : string | null) {
    this.$emit('input', `${this.fromRange || ''}::${value || ''}`)
  }
}
