<template>
  <v-app :dark="darkMode" :class="{ 'mobile-app': mobileApp, [mobilePlatform]: true }">
    <router-view class="main-container"/>
    <div :class="{ 'pre-ribbon': true, [`${env}-ribbon`]: true }" v-if="preBarVisible">
      <div class="pre-text">
        <div>AMBIENTE {{env.toUpperCase()}}</div>
        <div>SodlabVittra {{version}}-{{env}}</div>
      </div>
    </div>
      <v-snackbar
        v-model="snackbarVisible"
        :top="snackbarProps.top"
        :bottom="snackbarProps.bottom"
        :left="snackbarProps.left"
        :right="snackbarProps.right"
        :timeout="snackbarProps.timeout"
        multi-line
      >
        {{ snackbarProps.text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackbarProps.color ? '' : 'primary'"
            v-bind="attrs"
            text 
            @click="snackbarVisible = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
    <dialog-container/>
  </v-app>
</template>
<script>
import DialogContainer from '@/components/dialogs/DialogContainer.vue'

export default {
  name: 'App',
  components: {
    DialogContainer
  },
  mounted () {
    if (window.mobileApp) {
      const bootstrap = document.getElementById('bootstrap')
      if (bootstrap) {
        bootstrap.remove()
      }
      this.updateNotch()
      window.addEventListener('resize', () => this.updateNotch())
    }
    this.$vuetify.theme.dark = this.$store.state.app.darkMode
  },
  data () {
    return {
      env: process.env.VUE_APP_ENV || 'staging',
      version: process.env.VUE_APP_VERSION || 'dev'
    }
  },
  methods: {
    updateNotch () {
      // Fix for android "notches"
      if (window.AndroidNotch) {
        const style = document.documentElement.style
        window.AndroidNotch.getInsetTop(px => style.setProperty('--notch-inset-top', `${px || 24}px`), console.error)
        window.AndroidNotch.getInsetRight(px => style.setProperty('--notch-inset-right', `${px}px`), console.error)
        window.AndroidNotch.getInsetBottom(px => style.setProperty('--notch-inset-bottom', `${px}px`), console.error)
        window.AndroidNotch.getInsetLeft(px => style.setProperty('--notch-inset-left', `${px}px`), console.error)
      }
    }
  },
  watch: {
    darkMode (value) {
      this.$vuetify.theme.dark = value
    }
  },
  computed: {
    darkMode () {
      return this.$store.state.app.darkMode
    },
    mobileApp () {
      return window.mobileApp
    },
    mobilePlatform () {
      if (!this.mobileApp || !window.cordova) return 'desktop'
      return window.cordova.platformId
    },
    preBarVisible () {
      return !['prod', 'bigdata'].includes(process.env.VUE_APP_ENV) ||
        (
          this.$store.state.auth.user &&
          (
            this.$store.state.auth.user.roles.indexOf('admin') >= 0 ||
            this.$store.state.auth.user.roles.indexOf('superAdmin') >= 0
          )
        )
    },
    snackbarVisible: {
      get () {
        return this.$store.state.snackbar.visible
      },
      set () {
        return this.$store.commit('snackbar/hideSnackbar')
      }
    },
    snackbarProps: {
      get () {
        return this.$store.state.snackbar
      }
    }
  }
}
</script>
<style>
@font-face {
  font-family: 'Aspira Wide Regular';
  src: url(~@/assets/fonts/AspiraWide/Aspira-Wide-Regular.ttf) format("TrueType");
}
@font-face {
  font-family: 'Aspira Wide Demi';
  src: url(~@/assets/fonts/AspiraWide/Aspira-Wide-Demi.ttf) format("TrueType");
}
@font-face {
  font-family: 'Aspira Wide Light';
  src: url(~@/assets/fonts/AspiraWide/Aspira-Wide-Light.ttf) format("TrueType");
}
@font-face {
  font-family: 'Aspira Wide Medium';
  src: url(~@/assets/fonts/AspiraWide/Aspira-Wide-Medium.ttf) format("TrueType");
}
@font-face {
  font-family: 'Aspira Wide Bold';
  src: url(~@/assets/fonts/AspiraWide/Aspira-Wide-Bold.ttf) format("TrueType");
}
.background__logo {
  height: calc(100% - 50px);
  background: linear-gradient(rgba(255, 255, 255, 0.747), rgba(255, 255, 255, 0)),
    url('./assets/codeless.svg');
  background-size: 100vh;
  background-repeat: no-repeat;
  background-position-x: 45vw;
  background-position-y: 15vh;
}

.theme--dark .background__logo {
  background: linear-gradient(rgba(18, 18, 18, 0.747), rgba(18, 18, 18, 0)),
    url('./assets/codeless.svg');
  background-size: 100vh;
  background-repeat: no-repeat;
  background-position-x: 45vw;
  background-position-y: 15vh;
}

p {
  font-family: 'Aspira Wide Regular', sans-serif;
  font-size: 17px;
}
#collection-list__items .v-list-item--active {
  background-color: rgba(130, 186, 247, 0.185);
}
.v-btn__content {
  text-transform: capitalize;
  letter-spacing:1px;
  font-family: "Aspira Wide Regular", sans-serif;
}
</style>