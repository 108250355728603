

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import numeral from '@/plugins/numeral'

interface RUTFieldOptions {
  placeholder ?: string
}

@Component({})
export default class RUTField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  /** Validation Rules */

  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    // RUT Validation
    rules.push((v : string) => this.validateRut(v))
    return rules
  }

  /** Additional field options */
  get fieldOptions () : RUTFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput (rut : string) {
    if (rut.length < 3) return this.$emit('input', rut)
    const numbers = rut.replace(/[^\dkK]/g, '')
    const parts = numbers.split('')
    if (!parts.length) return this.$emit('input', '')
    const last = parts.pop()
    this.$emit('input', numeral(parts.join('')).format('0,0') + '-' + last)
  }

  /** Verificar RUT y DV */
  validateRut (input : string) {
    if (!input) return true
    // Verificar formato
    if (!(/^\d{1,2}\.?\d{3}\.?\d{3}-?[\w\d]$/).test(input)) return 'RUT Inválido'
    // Dividir RUT y Digito Verificador
    const rutParts = input.split('-')
    const rut = parseInt(rutParts[0].replace(/\./g, ''), 10)
    let dv : string | number = rutParts[1]
    if (!dv) return 'RUT Inválido'
    // k = 10
    if (dv.toLowerCase() === 'k') dv = 10
    // 0 = 11
    if (dv === '0') dv = 11
    dv = parseInt(dv.toString(), 10)
    // Serie para validad DV
    const serie = [2, 3, 4, 5, 6, 7]
    // Se toman los digitos individuales del RUT de derecha a izquierda
    const prod = rut.toString().split('').reverse()
      // Se multiplica cada digito por su correspondiente en la serie definida anteriormente (2,3,4,5,6,7), si se llega al final de la serie se repite la misma
      .map((d, i) => parseInt(d, 10) * serie[i % serie.length])
      // Se suman todos los productos
      .reduce((a, b) => a + b)
    // Se divide entre 11 y se toma el residuo
    const mod = prod % 11
    // Se resta el modulo de 11 para tomar el DV válido
    const dvValido = 11 - mod
    // Se compara con el DV introducido
    if (dv !== dvValido) return 'RUT Inválido'

    return true
  }
}
