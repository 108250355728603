export default {
    youtube(d : any) {
        if (d.type !== 'textDirective') return false
        // @ts-ignore
        this.tag('<iframe class="youtube-md" width="560" height="315"')
        if (d.attributes) {
            var youtubeId = d.attributes.src.split('www.youtube.com/watch?v=')[1] || d.attributes.src.split('youtu.be/')[1] || d.attributes.src.split('www.youtube.com/embed/')[1]
            // @ts-ignore
            this.tag(' src="https://www.youtube.com/embed/' + this.encode(youtubeId) + '"')
        }
        // @ts-ignore
        this.tag(' allowfullscreen="true" frameborder="0">')
        // @ts-ignore
        this.raw(d.label || '')
        // @ts-ignore
        this.tag('</iframe>')
    },
    iframe(d : any) {
        if (d.type !== 'textDirective') return false
        // @ts-ignore
        this.tag('<iframe class="iframe-md" ')
        if (d.attributes) {
            // @ts-ignore
            this.tag(`width="${d.attributes.width || 560}" height="${d.attributes.height || 315}" src="${this.encode(d.attributes.src)}"`)
        }
        // @ts-ignore
        this.tag(' frameborder="0">')
        // @ts-ignore
        this.raw(d.label || '')
        // @ts-ignore
        this.tag('</iframe>')
    }
}
