



















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FormField } from '@/models'
import { Field as DateField } from '../date'
import moment from '@/plugins/moment'

interface DateRangeFieldOptions {
  showRelativeOptions ?: boolean
}

@Component({
  components: {
    DateField
  }
})
export default class DateRangeField extends Vue {
  /** Current Value */
  @Prop({ type: String }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  datePicker: boolean = false
  dateModel: any = []
  overlay: boolean = false

  @Watch('value')
  clearSelect () {
    if(this.value === null) {
      (this.$refs.select as HTMLFormElement).reset()
    }
  }

  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  saveDateModel ( value : string[] ) {
    if (value[1]) {
      if (moment(value[1]).isAfter(value[0])) {
        this.dateModel = [value[0], value[1]]
      }
      else {
        this.dateModel = [value[1], value[0]]
      }
    }
    else {
      this.dateModel = [value[0], value[0]]
    }
    const fromDate = moment(this.dateModel[0]).startOf('day').toISOString()
    const toDate = moment(this.dateModel[1]).endOf('day').toISOString()
    this.$emit('input', `${fromDate}::${toDate}`)
    this.datePicker = false
    this.overlay = false
  }

  canceDateModel () {
    (this.$refs.select as HTMLFormElement).reset();
    this.emitNull()
    this.datePicker = false
    this.overlay = false
  }

  get showDate () {
    return [moment(this.dateModel[0]).format('DD-MM-YYYY').toString(), moment(this.dateModel[1]).format('DD-MM-YYYY').toString()].join(' ~ ')
  }

  emitNull () {
    this.dateModel = []
    this.$emit('input', null)
  }

  relativeItems = [
    { value: 'day', text: 'Hoy' },
    { value: 'yesterday', text: 'Ayer' },
    { value: 'week', text: 'Últimos 7 días' },
    { value: 'month', text: 'Últimos 30 días' },
    { value: 'custom', text: 'Personalizar...' },
  ]

  _relativeValue = ''

  get relativeValue () {
    return this._relativeValue
  }
  set relativeValue (value : string) {
    this._relativeValue = value

    let fromDate
    let toDate
    switch (value) {
      case 'day':
        fromDate = moment().startOf('day').toISOString()
        toDate = moment().endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'yesterday':
        fromDate = moment().subtract(1, 'day').startOf('day').toISOString()
        toDate = moment().subtract(1, 'day').endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'week':
        fromDate = moment().subtract(7, 'day').startOf('day').toISOString()
        toDate = moment().subtract(1, 'day').endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'month':
        fromDate = moment().subtract(30, 'day').startOf('day').toISOString()
        toDate = moment().subtract(1, 'day').endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'custom':
        this.datePicker = true
        this.dateModel = []
        this.overlay = true
        break
    }
  }

  /** Additional field options */
  get fieldOptions () : DateRangeFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get fromDate () {
    return this.value && this.value.split('::')[0] || null
  }

  set fromDate (value : string | null) {
    this.$emit('input', `${moment(value).startOf('day').toISOString() || ''}::${this.toDate || ''}`)
  }

  get toDate () {
    return this.value && this.value.split('::')[1] || null
  }

  set toDate (value : string | null) {
    this.$emit('input', `${this.fromDate || ''}::${moment(value).endOf('day').toISOString() || ''}`)
  }
}
