



import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface SelectFieldOptions {
  placeholder ?: string
  options : {
    value : string
    label : string
  }[]
  multi ?: boolean
}

@Component({})
export default class SelectFieldView extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: '' }) value !: string | string[]
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  /** Additional field options */
  get fieldOptions () : SelectFieldOptions {
    return this.schema.fieldOptions || this.schema.options || { options: [] }
  }

  get displayValue () {
    if (!this.value) return ''
    if (typeof this.value === 'string') {
      return (this.fieldOptions.options.find(o => o.value === this.value) || { label: this.value }).label
    } else {
      return this.value
        .map(v => (this.fieldOptions.options.find(o => o.value === v) || { label: v }).label)
        .join(', ')
    }
  }
}
