































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FileManagerFile, FormField } from '@/models'
import { DocumentFieldOptions } from '.'

@Component({
  components: {
    DocumentEditor: () => import('./Editor.vue')
  }
})
export default class DocumentEditorField extends Vue {
  /** Current Value */
  @Prop({ type: Object }) value ?: FileManagerFile
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables !: Record<string, any>

  editorOpen = false

  openModal () {
    // if (this.value) return
    this.editorOpen = true
  }

  get url () {
    if (!this.value) return ''
    const { bucket, key } = this.value
    return `https://s3.amazonaws.com/${bucket}/${key}`
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : DocumentFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue () {
    if (!this.value) return
    return this.value.name
  }

  get fileIcon () {
    if (!this.value) return ''
    if (this.value.type.startsWith('image/')) return 'photo'
    if (this.value.type === 'application/pdf') return 'picture_as_pdf'
    return 'description'
  }

  handleFileSelect (file : FileManagerFile) {
    this.$emit('input', file)
  }

  clearValue () {
    this.$emit('input', null)
  }
}
