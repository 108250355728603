import { EventEmitter } from 'events'

declare global {
  interface Window {
    WacomGSS_SignatureSDK : any
  }
}

/**
 * Helper class for signatures.
 */
export default class SignatureHelper extends EventEmitter {
  licenseKey = ''

  constructor (licenseKey : string) {
    super()
    this.licenseKey = licenseKey
  }

  /** Wacom Signature SDK instance */
  wgssSignatureSDK : any = null
  /** Control  */
  sigCtl : any = null

  /** Change the current help text */
  setHelpText (helpText = '') {
    this.emit('helpText', helpText)
  }

  /** Run an SDK function asynchronously */
  runAsync (fn : (...args : any[]) => any, responseIndex ?: number, statusIndex ?: number) {
    return new Promise((resolve, reject) => {
      fn((...args : any[]) => {
        // tslint:disable-next-line
        const status = statusIndex != null ? statusIndex : Math.max(0, args.length - 1)
        // tslint:disable-next-line
        const response = responseIndex != null ? responseIndex : Math.max(0, args.length - 2)

        if (status < 0 || args[status] === this.wgssSignatureSDK.ResponseStatus.OK) {
          resolve(args[response])
        } else {
          reject(args[status])
        }
      })
    })
  }

  /** Make sure the Wacom SDK is initialized */
  async init (licenseKey = this.licenseKey) {
    return new Promise((resolve, reject) => {
      // Check if the SDK is already running
      if (!this.wgssSignatureSDK || !this.wgssSignatureSDK.running) {
        // Create a new instance of the SDK
        this.wgssSignatureSDK = new window.WacomGSS_SignatureSDK(async () => {
          // Check if the creation was successful
          if (this.wgssSignatureSDK.running) {
            try {
              // Initialize SigCtl and load license
              this.sigCtl = await this.runAsync(cb => new this.wgssSignatureSDK.SigCtl(cb))
              await this.runAsync(cb => this.sigCtl.PutLicence(licenseKey, cb))
              resolve()
            } catch (e) {
              reject(e)
            }
          } else {
            // SDK couldn't be initialized.
            reject(-1)
          }
        }, 8000)
      } else {
        // The SDK is already up and running.
        resolve()
      }
    })
  }

  /** Initialize capture process */
  async initCapture () {
    this.setHelpText('Inicializando captura de firma...')
    try {
      await this.init(this.licenseKey)
      const dynCapt : any = await this.runAsync(cb => new this.wgssSignatureSDK.DynamicCapture(cb))
      // const signature = await this.runAsync(cb => this.sigCtl.GetSignature(cb))
      const sigCtlVer = await this.runAsync(cb => this.sigCtl.GetProperty('Component_FileVersion', cb))
      const dynCaptVer = await this.runAsync(cb => dynCapt.GetProperty('Component_FileVersion', cb))
      this.setHelpText('Dispositivo listo para capturar la firma.')
      return { dynCapt, sigCtlVer, dynCaptVer }
    } catch (e) {
      this.setHelpText('Error al iniciar proceso de captura.')
      throw e
    }
  }

  /** Start the capture process */
  async startCapture (who : string, why : string) {
    const { dynCapt } = await this.initCapture()
    return new Promise((resolve, reject) => {
      dynCapt.Capture(this.sigCtl, who, why, null, null, (dynCaptV : any, sigObj : any, status : any) => {
        const { ResponseStatus, DynamicCaptureResult } = this.wgssSignatureSDK
        switch (status) {
          case ResponseStatus.INVALID_SESSION:
            // Restart process
            resolve(this.startCapture(who, why))
            break
          case DynamicCaptureResult.DynCaptOK:
            // Success
            resolve(sigObj)
            break
          case DynamicCaptureResult.DynCaptCancel:
            // Cancelled
            this.setHelpText('Captura de firma cancelada')
            reject(new Error('Captura de firma cancelada'))
            break
          case DynamicCaptureResult.DynCaptNotLicensed:
            // Not licensed
            this.setHelpText('Error: no se ha detectado una licencia valida para utilizar el dispositivo')
            reject(new Error('Error: no se ha detectado una licencia valida para utilizar el dispositivo'))
            break
          default:
            // Generic error
            this.setHelpText('Error en la captura de la firma')
            reject(status)
        }
      })
    })
  }

  /** Render signature as a base64 url */
  async renderSignature (sigObj : any, format = 'png', width = 230, height = 132) {
    const { RBFlags } = this.wgssSignatureSDK

    const imgBase64 = await this.runAsync(cb => sigObj.RenderBitmap(
      format,
      width,
      height,
      0.7,
      0x00000000,
      0x00ffffff,
      RBFlags.RenderOutputBase64 | RBFlags.RenderColor32BPP | RBFlags.RenderBackgroundTransparent,
      0,
      0,
      cb
    ))

    return 'data:image/png;base64,' + imgBase64
  }

  /** Displays the Wacom SDK about box */
  async displayAboutBox () {
    await this.init()
    return this.runAsync(cb => this.sigCtl.AboutBox(cb))
  }

  /** Captures a signature and returns it as an image  */
  async captureSignatureAsImage (who : string, why : string, format = 'png', width = 460, height = 264) {
    const sigObj = await this.startCapture(who, why)
    return this.renderSignature(sigObj, format, width, height)
  }

  /** Captures a signature and returns the raw ink data */
  async captureRawSignature (who : string, why : string) {
    const sigObj : any = await this.startCapture(who, why)
    return this.runAsync(cb => sigObj.GetSigText(cb))
  }

  /** Captures both image and raw data */
  async captureHybrid (who : string, why : string, imgFormat = 'png', imgWidth = 460, imgHeight = 264) {
    const sigObj : any = await this.startCapture(who, why)
    return {
      image: await this.renderSignature(sigObj, imgFormat, imgWidth, imgHeight),
      ink: await this.runAsync(cb => sigObj.GetSigText(cb))
    }
  }
}
