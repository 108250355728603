import { ComponentType, Block } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'

export const tasks = {
  name: 'task',
  namespace: 'tasks',
  title: 'Tareas',
  titleSingle: 'Tarea',
  description: 'Procesos Automatizados',
  icon: 'event_available',
  color: 'red',
  overview: 'Las tareas permiten automatizar todo tipo de procesos, ya sea de carga de datos, validaciones, envio de correos, notificaciones, etc.',
  queryName: 'tasks',
  queryFields: `{
    _id
    name
  }`,
  searchable: true,
  hidden: true,

  transformResult: (task) => ({
    ...task,
    name: task.name
  }),
  
  fullViewEdit: () => ({
    component: import('./FullViewEdit/FullViewEdit.vue').then(e => e.default),
    loading: EditorLoading,
    error: ComponentError
  } as unknown as Vue.AsyncComponent),
} as ComponentType<Block>
