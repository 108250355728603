





import { Component, Vue, Prop } from 'vue-property-decorator'
import { FileManagerFile } from '@/models'

@Component({})
export default class DocumentFieldView extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value !: FileManagerFile | string

  get url () {
    if (!this.value) return ''
    if (typeof this.value === 'string') return this.value
    const { bucket, key } = this.value
    return `https://s3.amazonaws.com/${bucket}/${key}`
  }
}
