

















import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
import { Collection, FormField } from '@/models'
import gql from 'graphql-tag'

interface FieldOptionsFieldOptions {
  placeholder ?: string
  collectionId ?: string
  multi ?: boolean
  parentCollection ?: string
}

interface FieldOptionsFieldSchema extends FormField {
  parentCollection ?: string
  parentField ?: string
}

@Component({
  components: {
    Field: () => import('@/components/fields/Field.tsx')
  },
  apollo: {
    collection: {
      query: gql`query collectionFields ($collectionId : ID) {
        collection (collectionId: $collectionId) {
          fields {
            name
            type
            options
          }
        }
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          collectionId: this.collectionId
        }
      }
    }
  }
})
export default class FieldOptionsField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: '' }) value !: any
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FieldOptionsFieldSchema
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables !: Record<string, any>

  @Inject() fieldValues ?: () => Record<string, any>

  collection : Partial<Collection> | null = null
  searchQuery = ''

  get field () {
    return this.collection?.fields?.find(f => f.name === this.fieldName)
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : FieldOptionsFieldOptions {
    return this.schema.fieldOptions || this.schema.options || {}
  }

  get collectionId () {
    if (this.fieldOptions.collectionId) return this.fieldOptions.collectionId
    if (this.fieldValues) {
      const values = this.fieldValues()
      if (this.schema.parentCollection) return values[this.schema.parentCollection]
      if (values.collectionId) return values.collectionId
    }
    if (this.environmentVariables.collectionId) return this.environmentVariables.collectionId
  }

  get fieldName () {
    if (!this.collectionId || !this.fieldValues || !this.schema.parentField) return ''
    return this.fieldValues()[this.schema.parentField] || ''
  }

  get fieldValue () {
    return this.value
  }

  set fieldValue (value : any) {
    this.$emit('input', value)
  }
}
