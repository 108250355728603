import { ComponentType, Filter } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const filters = {
  name: 'filter',
  namespace: 'filters',
  title: 'Filtros',
  titleSingle: 'Filtro',
  icon: 'filter_list',
  color: 'deep-orange',
  overview: 'Los filtros son una forma de filtrar información de las colecciones. Con CODELESS puedes crear tus propios filtros y con tus propios parámetros para encontrar específicamente lo que buscas en una tabla o colección.',

  queryName: 'filters',
  queryFields: `{
    _id
    name
    title
    collectionId
  }`,
  searchable: true,
  hidden: true,

  transformResult: (filter) => ({
    ...filter,
    name: filter.name,
    title: filter.title,
    subtitle: filter.name,
    href: `filters/${filter._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Filter>
