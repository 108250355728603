import { PushNotificationProvider } from '.'

export default class WebPushProvider implements PushNotificationProvider {
  async getState () {
    let firebaseToken = ''
    try {
      firebaseToken = await this.getFirebaseToken()
    } catch (e) {
      console.error(e)
    }
    return {
      available: !!firebaseToken,
      enabled: localStorage.pushToken === firebaseToken
    }
  }

  async subscribe () {
    const { available, enabled } = await this.getState()
    if (!available || enabled) return
    const allowed = await this.requestNotificationPermission()
    if (!allowed) {
      throw new Error('La aplicación no tiene permiso para mostrar notificaciones actualmente')
    }
    const firebaseToken = await this.getFirebaseToken()
    localStorage.pushToken = firebaseToken
    return {
      firebase: true,
      // APNSToken: await this.getAPNSToken(),
      firebaseToken
    }
  }

  async unsubscribe () {
    const { available, enabled } = await this.getState()
    if (!available || !enabled) return
    const firebaseToken = await this.getFirebaseToken()
    delete localStorage.pushToken
    await window.FirebasePlugin.unregister()
    return {
      firebase: true,
      // APNSToken: await this.getAPNSToken(),
      firebaseToken
    }
  }

  async getAPNSToken () : Promise<string | null> {
    if (window.FirebasePlugin.getAPNSToken) {
      return new Promise(window.FirebasePlugin.getAPNSToken)
    } else {
      return null
    }
  }

  async getFirebaseToken () : Promise<string> {
    return new Promise(window.FirebasePlugin.getToken)
  }

  async requestNotificationPermission () : Promise<boolean> {
    return new Promise(window.FirebasePlugin.grantPermission)
  }
}
