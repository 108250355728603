









































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Block, Field, FormField, Hook, PaginatedQueryResult, TableField, TableFieldType, Collection } from '@/models'
import { BlockFragment } from '@/components/componentTypes/blocks/fragments'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import HookSelect from '@/components/fields/hookSelect/Field.vue'

import Loading from '@/components/Loading.vue'
import _cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import _isEqual from 'lodash/isEqual'
import _snakeCase from 'lodash/snakeCase'
import gql from 'graphql-tag'

interface TableFieldsFieldOptions {
  only ?: string[]
}

@Component({
  components: {
    ComponentSelect,
    CollectionFieldSelect,
    Fields: () => import('@/components/form/Fields.vue'),
    Loading,
    draggable,
    KeyValueList,
    HookSelect
  },
  apollo: {
    collection: {
      query: gql`query collectionFields ($collectionId : ID) {
        collection (collectionId: $collectionId) {
          _id
          fields {
            name
            label
          }
        }
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          collectionId: this.environmentVariables.collectionId
        }
      },
      skip () {
        return !(this.environmentVariables?.collectionId)
      }
    }
  }
})
export default class TableFieldsField extends Vue {
  /** Current Value */
  @Prop({ type: Array, default: () => [] }) value !: TableField[]
  /** Validation Errors */
  @Prop() errorMessages !: string
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables !: Record<string, any>

  collection : Partial<Collection> | null = null

  iconSheetOpen : Record<number, boolean> = {}
  editableSheetOpen : Record<number, boolean> = {}

  get fieldOptions () : TableFieldsFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get fieldTypes () {
    return [
      { value: 'field', label: 'Campo', icon: 'data_usage' },
      { value: 'editableField', label: 'Campo Editable', icon: 'keyboard' },
      { value: 'selectIconButton', label: 'Seleccionar variable', icon: 'done' },
      { value: 'routeIconButton', label: 'Ir a una ruta', icon: 'attach_file' },
      { value: 'deleteRowByUser', label: 'Eliminar documento', icon: 'delete_forever' },
      { value: 'runHooks', label: 'Ejecutar hooks', icon: 'memory' },
      { value: 'postItem', label: 'Enviar documento a una URL', icon: 'cloud_upload' },
      { value: 'multipleSelect', label: 'Selección múltiple', icon: 'done_all' },
      { value: 'multipleSelectGroup', label: 'Selección múltiple (Grupo)', icon: 'menu_open' },
      { value: 'indicator', label: 'Indicador', icon: 'subtitles' },
      { value: 'modal', label: 'Abrir modal', icon: 'open_in_browser' },
      { value: 'inlineBlock', label: 'Bloque expandible', icon: 'expand' },
    ].filter(t => this.fieldOptions.only ? this.fieldOptions.only.includes(t.value) : true)
  }

  get syncValue () {
    return this.value
  }

  set syncValue (v) {
    this.$emit('input', v)
  }

  normalizedIcon (icon = '') {
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  addField () {
    this.$emit('input', [
      ...(this.value || []),
      { options: {}, label: '' }
    ])
  }

  addAllFields () {
    if (!this.collection || !this.collection.fields) return
    const fields = this.collection.fields.map(f => ({
      type: TableFieldType.Field,
      label: f.label,
      fieldName: f.name
    }))
    this.$emit('input', [
      ...(this.value || []),
      ...fields
    ])
  }

  handleTypeChange (index : number, type : string) {
    this.$emit('input', this.value.map((f, i) => i === index ? { ...f, type, options: {}, label: '' } : f))
  }

  handleLabelChange (index : number, label : string) {
    this.$emit('input', this.value.map((f, i) => i === index ? { ...f, label } : f))
  }

  handleFieldChange (index : number, fieldName : string) {
    this.$emit('input', this.value.map((f, i) => i === index ? { ...f, fieldName } : f))
  }

  handleOptionChange (index : number, optionName : string, value : any) {
    this.$emit('input', this.value.map((f, i) => i === index ? { ...f, options: { ...f.options, [optionName]: value } } : f))
    if (optionName === 'viewPath') {
      this.$nextTick(() => {
        const params = value.match(/:[\w\d]+/g)
        if (params) {
          this.$emit('input', this.value.map((f, i) => i === index ? { ...f, options: { ...f.options, variableMap: Object.fromEntries(params.map((p : string) => [p.slice(1), ''])) } } : f))
        }
      })
    }
  }

  removeField (index : number) {
    this.$emit('input', this.value.filter((f,i) => i !== index))
  }

  removeAllFields () {
    this.$emit('input', [])
  }
}
