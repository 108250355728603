import { ComponentType, Block } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'

export const blocks = {
  name: 'block',
  namespace: 'blocks',
  title: 'Bloques de Contenido',
  titleSingle: 'Bloque de Contenido',
  description: 'Contenido Personalizable',
  icon: 'polymer',
  color: 'green',
  overview: 'Los bloques de contenido los usamos para crear elementos personalizados para un proyecto. Con CODELESS puedes crear bloques de contenido con código HTML o texto enriquecido que te ayudará a potenciar tu plataforma de software.',
  queryName: 'blocks',
  queryFields: `{
    _id
    name
    title
    type
  }`,
  searchable: true,

  transformResult: (block) => ({
    ...block,
    name: block.name,
    title: block.title,
    subtitle: {
      html: 'Contenido HTML',
      text: 'Texto Enriquecido'
    }[block.type!],
    icon: {
      html: 'polymer',
      text: 'line_style'
    }[block.type!],
    href: `blocks/${block._id}`
  }),

  viewSkeleton: 'article',

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  fullViewEdit: () => ({
    component: import('./FullViewEdit/FullViewEdit.vue').then(e => e.default),
    loading: EditorLoading,
    error: ComponentError
  } as unknown as Vue.AsyncComponent),
  view: () => asyncImport(import('./View.vue'))
} as ComponentType<Block>
