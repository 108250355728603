import { ComponentType, View } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const views = {
  name: 'view',
  namespace: 'views',
  title: 'Vistas',
  titleSingle: 'Vista',
  description: 'Conjunto de componentes',
  icon: 'dashboard',

  queryName: 'views',
  queryFields: `{
    _id
    environmentId
    name
    path
  }`,
  searchable: true,
  hidden: true,

  transformResult: (view) => ({
    ...view,
    title: view.name,
    subtitle: view.path,
    href: `/${view.environmentId}/design/${view._id}`
  }),

  create: () => asyncImport(import('./Create.vue'))
} as ComponentType<View>
