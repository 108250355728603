


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface PhoneFieldOptions {
  placeholder ?: string
}

@Component({})
export default class PhoneField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  /** Validation Rules */

  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    // Phone Validation
    // rules.push((v ?: string) => this.validateRut(v))
    return rules
  }

  /** Additional field options */
  get fieldOptions () : PhoneFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput (...args : any[]) {
    this.$emit('input', ...args)
  }
}
