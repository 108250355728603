import { FilterWithSchema } from './Filter'
import { Collection } from '../Collection'

export interface Table {
  _id : string
  environmentId : string
  title : string
  name : string
  createdAt : Date
  collectionId : string
  collection ?: Collection
  allowsNoFilter : boolean
  filterByDefault : string
  orderByDefault ?: string
  orderByAsc ?: boolean
  sortable ?: boolean
  filtersIds : string[]
  filters ?: FilterWithSchema[]
  fields : TableField[]
  orderFiltersByName : boolean
  footer : TableFooterField[]
  exportable : boolean
  exportWithId : boolean
  defaultLimit : number
  roles : string[]
  unlimited ?: boolean
  cssClassField ?: string
  fixedHeaders ?: boolean
  disableRefresh : boolean
}

export interface TableField {
  type : TableFieldType
  fieldName : string
  label : string
  options : any
  sortable ?: boolean
}

export enum TableFieldType {
  Field = 'field',
  EditableField = 'editableField',
  SelectIconButton = 'selectIconButton',
  RouteIconButton = 'routeIconButton',
  DeleteRowByUser = 'deleteRowByUser',
  RunHooks = 'runHooks',
  PostItem = 'postItem',
  MultipleSelect = 'multipleSelect',
  MultipleSelectGroup = 'multipleSelectGroup',
  Indicator = 'indicator',
  Modal = 'modal',
  InlineBlock = 'inlineBlock',
  DragHandle = 'drag_handle'
}

export interface TableFooterField {
  type : TableFooterFieldType
  indicatorId ?: string
  parameter ?: string
  text ?: string
}

export enum TableFooterFieldType {
  Text = 'text',
  Indicator = 'indicator',
  Parameter = 'parameter'
}
