

































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'


interface RatingFieldOptions {
  num ?: number,
  startBackColor?: string,
  startAccentColor?: string,
  hover?: boolean,
  size?: string
}
@Component({})
export default class RatingField extends Vue {

  /** Current Value */
  @Prop({ type: Number }) value ?: number | null
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  get currentValue () {
    if (this.value == null) return 0
    return this.value
  }

  set currentValue (value : number ) {
    this.$emit('input', value)
  }

  get fieldOptions () : RatingFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

   /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: number | null) => v != 0 || 'Campo Requerido.')
    }
    return rules
  }

}
