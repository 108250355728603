








































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FormField } from '@/models'
import micromark from 'micromark'
import MonacoEditor from '@/plugins/monaco'
import * as monaco from 'monaco-editor'
import UploadModal from '@/components/fields/file/UploadModal.vue'
// @ts-ignore
import math from 'micromark-extension-math'
// @ts-ignore
import mathHtml from 'micromark-extension-math/html'
// @ts-ignore
import sintaxis from 'micromark-extension-directive'
// @ts-ignore
import html from 'micromark-extension-directive/html'
import htmlDirectives from './htmlDirectives'

@Component({
  components: {
    MonacoEditor,
    UploadModal,
  }
})
export default class MarkdownField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean
  
  previewContent : string = ''
  uploadModalOpen : boolean = false
  content: string= ''
  videoDialog: boolean = false
  linkVideo: string = ''

  get textareaRef() {
    return `field-${this.name}__textarea-code`
  }

  mounted() {
    this.previewContent = this.convertToMarkdown(this.value ? this.value : '')
  }  

  convertToMarkdown(value: string) {
    let resp = micromark(value , {
      // @ts-ignore
      extensions: [math, sintaxis()],
      htmlExtensions: [mathHtml(), html({youtube : htmlDirectives.youtube, iframe : htmlDirectives.iframe})]
    })
    return resp
  }

  @Watch('value')
  updatePreview(){
    this.previewContent = this.convertToMarkdown(this.value ? this.value : '')
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get currentValue () {
    return this.value ? this.value : ''
  }

  set currentValue (newValue : string) {
    this.$emit('input', newValue)
  }

  handlerVideoSelect() {
    this.videoDialog = false
    var videoString = ` :youtube{src="${this.linkVideo}"} `
    this.insertTextarea(videoString)
    this.linkVideo = ''
  }

  insertH1Text() {
    this.insertInitTextArea('#')
  }
  insertH2Text() {
    this.insertInitTextArea('##')
  }
  insertH3Text() {
    this.insertInitTextArea('###')
  }

  handleImageSelect (value: any) {
    var imageString = ` ![](${value.url}) `
    this.insertTextarea(imageString)
  }

  insertTextarea(value: string) {
    const insertText = value
    if (!insertText.length) return

    // @ts-ignore
    const textarea = this.$refs[this.textareaRef]
    // @ts-ignore
    const sentence = textarea.value
    const len = sentence.length
    // @ts-ignore
    let pos = textarea.selectionStart
    if (pos === undefined) {
      pos = 0
    }
    const before = sentence.substr(0, pos)
    const after = sentence.substr(pos, len)
    this.currentValue = before + insertText + after
    this.$nextTick().then(() => {
      // @ts-ignore
      textarea.selectionStart = pos + insertText.length
    })
  }

  insertInitTextArea(value: string) {
    const textarea = this.$refs[this.textareaRef]
    // @ts-ignore
    const sentence = textarea.value


    // @ts-ignore
    var beforeCursor = sentence.substr(0, textarea.selectionStart)
    // @ts-ignore
    var afterCursor = sentence.substr(textarea.selectionStart, sentence.length)


    // Dividiendo en un arreglo
    var arrayBeforeCursor = beforeCursor.split("\n")
    
    // Creamos la nueva linea a partir de el ultimo elemento del arreglo anterior
    var newLine = value + ' ' + arrayBeforeCursor[arrayBeforeCursor.length-1]

    // Reemplazamos el ultimo arreglo por el que creamos y lo unimos usando join
    arrayBeforeCursor.pop()
    arrayBeforeCursor.push(newLine)
    var newBeforeCursor = arrayBeforeCursor.join("\n")

    // Imprimimps todo en el textArea
    this.currentValue = newBeforeCursor + afterCursor
  }

  uploadImage() {
    this.uploadModalOpen = true
  }

}
