import store from '@/store'
import * as CommonDialogs from './common'
import { Dialog } from '@/models/Dialogs'
import { reject } from 'lodash'
import Chance from 'chance'
const chance = new Chance()

export function showDialog (dialog : Dialog) {
  store.commit('dialogs/addDialog', {
    ...dialog,
    open: true
  })
}

export function showDialogPromise (dialog : Partial<Dialog>) {
  return new Promise(resolve => {
    store.commit('dialogs/addDialog', {
      dismissable: true,
      open: true,
      ...dialog,
      handler: resolve
    })
  })
}

export interface AlertDialogOptions {
  title ?: string
  okButtonText ?: string
  okButtonIcon ?: string
  okButtonColor ?: string
}

export function alert (message : string, options : AlertDialogOptions = {}) : Promise<boolean> {
  return showDialogPromise({
    id: chance.guid(),
    component: CommonDialogs.Alert,
    props: {
      message,
      ...options
    },
    width: 720
  }) as Promise<boolean>
}

export interface ConfirmDialogOptions extends AlertDialogOptions {
  cancelButtonText ?: string
  cancelButtonIcon ?: string
  cancelButtonColor ?: string
}

export function confirm (message : string, options : ConfirmDialogOptions = {}) : Promise<boolean> {
  return showDialogPromise({
    id: chance.guid(),
    component: CommonDialogs.Confirm,
    props: {
      message,
      ...options
    },
    width: 720
  }) as Promise<boolean>
}

export interface PromptDialogOptions extends ConfirmDialogOptions {
  placeholder ?: string
  defaultValue ?: string
}

export function confirmDelete (message : string, options : ConfirmDialogOptions = {}) : Promise<boolean> {
  return showDialogPromise({
    id: chance.guid(),
    component: CommonDialogs.ConfirmDelete,
    props: {
      message,
      ...options
    },
    width: 720
  }) as Promise<boolean>
}


export function prompt (message : string, options : PromptDialogOptions = {}) : Promise<string | boolean> {
  return showDialogPromise({
    id: chance.guid(),
    component: CommonDialogs.Prompt,
    props: {
      message,
      ...options
    },
    width: 720
  }) as Promise<string | boolean>
}

export interface TOTPDialogOptions extends PromptDialogOptions {
  invalidAttempt ?: boolean
}
export function totpPrompt (options : TOTPDialogOptions = {}) : Promise<string | boolean> {
  return showDialogPromise({
    id: chance.guid(),
    component: CommonDialogs.TOTP,
    props: options,
    width: 480
  }) as Promise<string | boolean>
}
