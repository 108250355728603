import { createDecorator } from 'vue-class-component'
import { ThrottleSettings } from 'lodash'
import _throttle from 'lodash/throttle'

/**
 * Throttles a Vue method (limits the invocation interval)
 */
export const Throttle = function (wait ?: number, options ?: ThrottleSettings) {
  return createDecorator(function (options, key) {
    if (!options.methods) options.methods = {}
    const originalMethod = options.methods[key]
    if (!originalMethod) throw new Error('@Throttle can only be used in methods.')
    // @ts-ignore
    options.methods[key] = _throttle(originalMethod, wait, options)
  })
}
