import { ComponentType, Role } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const roles = {
  name: 'role',
  namespace: 'roles',
  title: 'Roles',
  titleSingle: 'Rol',
  description: 'Controla permisos de usuario',
  icon: 'group',
  overview: 'Los roles son aquellos elementos que otorgan permisos a los usuarios de un ambiente. Crea los roles suficientes y necesarios para que los usuarios de un ambiente solo tengan acceso a los datos con los que trabajan y no más.',

  queryName: 'roles',
  queryFields: `{
    _id
    environmentId
    name
  }`,
  searchable: false,
  hidden: true,

  transformResult: (role) => ({
    ...role,
    name: role.name,
    title: role.name,
    subtitle: ' ',
    href: `/${role.environmentId}/components/roles/${role._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Role>
