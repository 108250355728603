























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ConfirmDeleteDialog extends Vue {
  @Prop({ type: String }) title ?: string
  @Prop({ type: String }) message ?: string
  @Prop({ type: String, default: 'Eliminar' }) okButtonText ?: string
  @Prop({ type: String, default: 'delete' }) okButtonIcon ?: string
  @Prop({ type: String, default: '' }) okButtonColor ?: string
  @Prop({ type: String, default: 'Cancelar' }) cancelButtonText ?: string
  @Prop({ type: String, default: '' }) cancelButtonIcon ?: string
  @Prop({ type: String, default: '' }) cancelButtonColor ?: string
}
