import Vue from 'vue'
import VueApollo from 'vue-apollo'
import getClient, { ClientOptions } from '@/plugins/vue-apollo/client'
import { GraphQLError } from 'graphql'
import { alert } from '@/components/dialogs'

// Call this in the Vue app file
export function createProvider (options : ClientOptions) : VueApollo {
  // Create vue apollo provider
  return new VueApollo({
    defaultClient: getClient(),
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler (e) {
      let message = e.message
      if (e.graphQLErrors) message = e.graphQLErrors.map((e : GraphQLError) => e.message).join(', ')
      for (const err of e.graphQLErrors) {
        // @ts-ignore
        if (err.validationErrors) console.log('Validation Error:', err.validationErrors)
        // @ts-ignore
        if (err.type === 'invalidTwoFactorCode') alert('El código de doble factor es inválido.', { okButtonText: 'Cerrar', okButtonIcon: 'close' }).catch()
      }
      console.error(message, e.graphQLErrors)
    }
  })
}

Vue.use(VueApollo)
