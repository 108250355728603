






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import gql from 'graphql-tag'
import { PaginatedQueryResult, ComponentListItem } from '@/models'

@Component({
  components: {
    Loading
  },
  apollo: {
    result: {
      query () {
        const varDefinitions = Object.keys(this.queryVariables || {}).map(k => {
          return `$${k}: ${this.queryVariables[k].type}`
        }).join(', ')
        const varParams = Object.keys(this.queryVariables || {}).map(k => `${k}: $${k}`)
        return gql`query paginated_result ($page: BigInt, $limit: BigInt, $filter: String${varDefinitions ? ', ' + varDefinitions : ''}) {
          result: ${this.queryName} (page: $page, limit: $limit, filter: $filter${varParams ? ', ' + varParams : ''}) {
            _id
            totalCount
            totalPages
            hasNextPage
            hasPreviousPage
            items ${this.queryFields}
          }
        }`
      },
      variables () {
        const additionalVars : any = {}
        Object.keys(this.queryVariables || {}).forEach(k => {
          additionalVars[k] = this.queryVariables[k].value
        })
        return {
          page: this.currentPage,
          limit: this.limit || 10,
          filter: this.searchQuery,
          ...additionalVars
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class PaginatedList extends Vue {
  @Prop({ type: String, required: true }) queryName !: string
  @Prop({ type: String, required: true }) queryFields !: string
  @Prop({ type: Object, default: () => ({}) }) queryVariables !: {
    [key : string] : {
      type : string
      value: any
    }
  }[]
  @Prop({ type: Number, default: 1 }) initialPage !: number
  @Prop({ type: Number, default: 10 }) limit !: number

  @Prop({ type: Boolean, default: false }) addButton !: boolean
  @Prop({ type: String, default: 'primary' }) addButtonColor !: string
  @Prop({ type: Boolean, default: false }) searchBox !: boolean
  @Prop({ type: Boolean, default: false }) squareIcons !: boolean
  @Prop({ type: String, default: 'apps' }) defaultIcon !: string

  @Prop({ type: String }) accentColor ?: string

  @Prop({ type: Function }) transformResult ?: (items : any[]) => ComponentListItem[]

  result ?: PaginatedQueryResult<any>
  page = 0
  searchQuery = ''

  get currentPage () {
    const page = this.page || this.initialPage || 1
    return page <= this.totalPages ? page : this.totalPages
  }

  set currentPage (value : number) {
    this.page = value
  }

  get totalPages () {
    return this.result && this.result.totalPages || 1
  }

  get displayItems () {
    if (!this.result) return []

    return this.result.items
      // TODO: Sort
      .map(this.transformResult || (e => e))
  }

  get iconStyle () {
    return {
      height: 'auto',
      'border-radius': this.squareIcons ? '0' : undefined
    }
  }


  update () {
    return this.$apollo.queries.result.refetch()
  }
}
