import { FormSchema } from '@/models/components'

export interface Field {
  name : string
  label : string
  type : FieldType,
  fieldType ?: {
    _id : string
    allowedOperatorsIds : string
  }
  optional : boolean
  options : any
}

export interface FieldTypeDefinition {
  _id : string
  name : string
  optionsParams : FormSchema
}

export enum FieldType {
  Boolean = 'boolean',
  CheckBox = 'checkbox',
  ColorPicker = 'colorPicker',
  Currency = 'currency',
  Date = 'date',
  DateTime = 'datetime',
  Document = 'document',
  Email = 'email',
  File = 'file',
  FileURL = 'fileURL',
  HTML = 'html',
  Hyperlink = 'hyperlink',
  ManyOf = 'manyOf',
  MultipleSelect = 'multipleSelect',
  Number = 'number',
  OneOf = 'oneOf',
  Percentage = 'percentage',
  Phone = 'phone',
  RUT = 'rut',
  select = 'select',
  String = 'string',
  TextArea = 'textArea',
  Rating = 'rating'
}
