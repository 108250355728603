import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloLink, Operation, Observable, split } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { HttpLink } from 'apollo-link-http'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { OperationDefinitionNode, GraphQLError } from 'graphql'
import { getHeaders, getSession, setSession, signRequest, unsetSession } from './auth'
import { RetryLink } from 'apollo-link-retry'
import { ErrorLink } from 'apollo-link-error'
import { Session } from '@/models/Session'
import { totpPrompt, alert } from '@/components/dialogs'

export interface ClientOptions {
  /** URL of the GraphQL endpoint */
  endpointUrl ?: string
  /** URL of the subscriptions (websocket) endpoint */
  subscriptionUrl ?: string
  /** Function to call when a 2FA code is prompted */
  prompt2fa ?: (invalidAttempt : boolean) => Promise<string>
  /** Max number of batch queries to execute at a time */
  batchInterval ?: number
  /** Whether to retry requests or not */
  shouldRetry ?: (attemptCount : number, operation : Operation, lastError : any) => boolean
  /** Function to invoke on error */
  onError ?: (error : any) => void
  /** Function that returns additional headers to append to the requests */
  getHeaders ?: () => Record<string, string>
}

function getAPIEndpoints () {
  let apiDomain = ({
    'portugal.apps.sodlab.com': 'api-portugal.apps.sodlab.com'
    /*
    'puentesmaule.apps.sodlab.com': 'api-puentesmaule.apps.sodlab.com',
    'wscopec.apps.sodlab.com': 'api-wscopec.apps.sodlab.com',
    'lubsal.apps.sodlab.com': 'api-lubsal.apps.sodlab.com',
    'pinos.apps.sodlab.com': 'api-pinos.apps.sodlab.com',
    'atm.apps.sodlab.com': 'api-atm.apps.sodlab.com',
    'lagostorrealba.apps.sodlab.com': 'api-lagostorrealba.apps.sodlab.com'
    */
  } as Record<string, string>)[window.location.hostname]

  if (apiDomain) {
    return {
      endpointUrl: `https://${apiDomain}/graphql`,
      subscriptionUrl: `wss://${apiDomain}/subscriptions`
    }
  }

  console.log('Fallback API...')
  return {
    endpointUrl: process.env.VUE_APP_API_ENDPOINT || 'http://localhost:3000/graphql',
    subscriptionUrl: process.env.VUE_APP_SUB_ENDPOINT || 'ws://localhost:3000/subscriptions'
  }
}

const defaultOptions : ClientOptions = {
  ...getAPIEndpoints(),
  prompt2fa: async (invalidAttempt : boolean) => await totpPrompt({ invalidAttempt }) as string || '',
  batchInterval: 20,
  // shouldRetry: () => true,
  onError: e => {
    let message = e.message
    if (e.graphQLErrors) message = e.graphQLErrors.map((e : GraphQLError) => e.message).join(', ')
    console.error(e)
    for (const err of e.graphQLErrors) {
      if (err.validationErrors) console.log('Validation Error:', err.validationErrors)
    }
  },
  getHeaders: () => ({})
}

let client : ApolloClient<any> | undefined
let wsClient : SubscriptionClient | undefined

export default function getClient (options ?: ClientOptions) {
  if (!client || options) client = createClient(options)
  return client
}

function createClient (customOptions : ClientOptions = { }) {
  const options = { ...defaultOptions, ...customOptions }
  // Create Links
  const links : ApolloLink[] = [createErrorLink(options)]
  const httpLink = createHTTPLink(options)
  if (options.subscriptionUrl) {
    wsClient = createWSLink(options)

    links.push(split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
        return kind === 'OperationDefinition' && operation === 'subscription'
      },
      // @ts-ignore
      wsClient,
      httpLink
    ))
  } else {
    links.push(httpLink)
  }
  // Create client
  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache({
      cacheRedirects: {
        Query: {}
      }
    })
  })
}

function createErrorLink (options : ClientOptions) {
  return new ErrorLink(({ graphQLErrors, networkError, response, operation, forward }) => {
    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        if (
          // @ts-ignore
          graphQLError.error === 'PermissionsError' &&
          // @ts-ignore
          (graphQLError.type === 'needsTwoFactorCode' || graphQLError.type === 'invalidTwoFactorCode')
        ) {
          if (options.prompt2fa) {
            return new Observable(observer => {
              // @ts-ignore
              options.prompt2fa!(graphQLError.type === 'invalidTwoFactorCode')
              .then(code => {
                // @ts-ignore
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    // Re-add old headers
                    ...headers,
                    // Switch out old access token for new one
                    'X-ORION-TWOFACTOR': code
                  }
                }))
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                }

                // Retry last failed request
                forward(operation).subscribe(subscriber)
              })
              .catch(error => {
                // No refresh or client token available, we force user to login
                observer.error(error)
              })
            })
          }
        }
      }
      // console.error(graphQLErrors)
    }

    if (networkError) {
      // @ts-ignore
      if (networkError.statusCode === 400 && networkError.result.error === 'AuthError') {
        const session = getSession()
        console.log('Resetting session', session)
        if (session) {
          unsetSession()
        }
      } else {
        console.warn('Network error:', networkError)
      }
    }

    return options.onError!({ graphQLErrors, networkError, response, operation, forward })
  })
}

function createHTTPLink (options : ClientOptions) {
  const customFetch = async (url : string, fetchOptions : RequestInit) => {
    const authHeaders = await getHeaders(fetchOptions.body as string)
    return fetch(url, {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        ...authHeaders as any,
        ...options.getHeaders!()
      }
    })
  }
  return ApolloLink.from([
    new RetryLink({
      attempts (count, operation, err) {
        /*
        if (operation.operationName === 'submitForm') {
          alert('Error de conexión. Antes de reintentar, póngase en contacto con soporte para evitar duplicidad.')
          return false
        }
        */
        if (options.shouldRetry) return options.shouldRetry(count, operation, err)
        if (err && err.result && err.result.error === 'AuthError' && err.result.message === 'nonceIsInvalid') {
          return count <= 10
        } else {
          return count <= 3
        }
      },
      delay (count) {
        return count * 1000 * Math.random()
      }
    }),
    options.batchInterval && options.batchInterval > 1
      ? new BatchHttpLink({
        uri: options.endpointUrl,
        fetch: customFetch,
        batchInterval: options.batchInterval
      })
      : new HttpLink({
        uri: options.endpointUrl,
        fetch: customFetch
      })
  ])
}

function createWSLink (options : ClientOptions) {
  return new SubscriptionClient(options.subscriptionUrl!, {
    reconnect: true,
    connectionParams: () => {
      const session = getSession()
      if (!session || !session.publicKey || !session.secretKey) return {}
      const nonce = Date.now()
      const signature = signRequest(nonce, 'websockethandshake')
      return {
        nonce,
        publicKey: session.publicKey,
        signature
      }
    }
  })
}

export async function performLogin (session : Session) {
  await setSession(session)
  if (client) await client.resetStore()
  if (wsClient) {
    // @ts-ignore
    wsClient.tryReconnect()
  }
}

export async function performLogout () {
  unsetSession()
  // @ts-ignore
  wsClient.tryReconnect()
}
