
/// <reference types="cordova" />
/// <reference types="cordova-plugin-statusbar" />
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class StatusBar extends Vue {
  @Prop({ type: Boolean, default: true }) overlaysWebView !: boolean
  @Prop({ type: String, default: '#00000000' }) backgroundColor !: string
  @Prop({ type: String, default: 'lightcontent' }) statusStyle !: string
  @Prop({ type: Boolean, default: false }) hidden !: boolean

  mounted () {
    this.updateHidden()
    this.updateOverlaysWebView()
    this.updateStyle()
    this.updateBackgroundColor()
  }

  befoureDestroy () {
    this.updateHidden(false)
    this.updateOverlaysWebView(true)
    this.updateStyle('blacktranslucent')
    this.updateBackgroundColor('#00000000')
  }

  render () {
    return null
  }

  @Watch('overlaysWebView')
  updateOverlaysWebView (value = this.overlaysWebView) {
    if (window.cordova && window.StatusBar) {
      window.StatusBar.overlaysWebView(value)
    }
  }

  @Watch('backgroundColor')
  updateBackgroundColor (value = this.backgroundColor) {
    if (window.cordova && window.StatusBar) {
      window.StatusBar.backgroundColorByHexString(value)
    }
  }

  @Watch('statusStyle')
  updateStyle (value = this.statusStyle) {
    if (window.cordova && window.StatusBar) {
      switch (value) {
        case 'lightcontent':
          window.StatusBar.styleLightContent()
          break
        case 'blacktranslucent':
          window.StatusBar.styleBlackTranslucent()
          break
        case 'blackopaque':
          window.StatusBar.styleBlackOpaque()
          break
        default:
          window.StatusBar.styleDefault()
      }
    }
  }

  @Watch('hidden')
  updateHidden (value = this.hidden) {
    if (window.cordova && window.StatusBar) {
      if (value) {
        window.StatusBar.hide()
      } else {
        window.StatusBar.show()
      }
    }
  }
}
