



































import { Dialog } from '@/models/Dialogs'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class DialogContainer extends Vue {
  get openDialogs () : Dialog[] {
    return this.$store.state.dialogs.openDialogs
  }

  getDialogOpen (dialogId : string) {
    return (this.openDialogs.find(d => d.id === dialogId) || {}).open
  }

  setDialogOpen (dialogId : string, open : boolean) {
    const d = this.openDialogs.find(d => d.id === dialogId)
    if (!d || d.resolved) return
    if (open) {
      this.$store.commit('dialogs/showDialog', dialogId)
    } else {
      this.resolveDialog(dialogId, false)
    }
  }

  resolveDialog (dialogId : string, result : any) {
    const d = this.openDialogs.find(d => d.id === dialogId)
    if (!d || d.resolved) return
    this.$store.commit('dialogs/hideDialog', dialogId)
    this.$store.commit('dialogs/resolveDialog', dialogId)
    setTimeout(() => {
      this.$store.commit('dialogs/removeDialog', dialogId)
    }, 1000)
    d.handler(result)
  }
}
