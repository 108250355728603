






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import Form from '@/components/form/Form.vue'
import defaultBg from '@/assets/login_bg0.jpg'
import defaultLogo from '@/assets/icono_horizontal.svg'
import _mapKeys from 'lodash/mapKeys'
import { Environment, Session } from '@/models'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'

@Component({
  components: {
    Loading,
    Form
  },
  apollo: {
    environment: {
      query: gql`query getEnvironment($url: String) {
        environment(url: $url) {
          _id
          name
          accentColor
          useCustomCss
          customCss
          logo {
            url
          }
          loginLogo {
            url
          }
          authBackgroundImage {
            url
          }
        }
      }`,
      variables () {
        return {
          url: this.domain
        }
      },
      skip () {
        return !this.domain
      }
    }
  }
})
export default class ResetPassword extends Vue {
  @Prop({ type: String, default: '' }) domain !: string
  @Prop({ type: String, default: '' }) token !: string
  environment : Partial<Environment> | null = null

  email = ''
  password = ''
  authenticating = false
  success = false


  validationErrors : Record<string, string> = {}

  @Watch('environment')
  async setEnvironmentVariables (env : Environment) {
    const style = document.getElementById('customCSS') as HTMLStyleElement
    if (env.accentColor) {
      this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.light.secondary = this.$vuetify.theme.themes.light.accent = env.accentColor
      this.$vuetify.theme.themes.dark.primary = this.$vuetify.theme.themes.dark.secondary = this.$vuetify.theme.themes.dark.accent = env.accentColor
    }
    if (env.useCustomCss && env.customCss) {
      style.innerText = env.customCss
    } else {
      style.innerText = ''
    }
  }

  get loggedIn () {
    return this.$store.getters['auth/loggedIn']
  }

  get user () {
    return this.$store.state.auth.user
  }

  get userAvatar () {
    return this.$store.getters['auth/userAvatarURL']
  }

  get logo () {
    if (this.$apollo.loading) return ''
    if (this.environment && this.environment.loginLogo) return this.environment.loginLogo.url
    if (this.environment && this.environment.logo) return this.environment.logo.url
    return defaultLogo
  }

  get bgStyle () {
    let background
    if (this.environment && this.environment.authBackgroundImage) {
      background = `url(${this.environment.authBackgroundImage.url}) fixed center / cover`
    } else if (!this.$apollo.loading) {
      background = `url(${defaultBg}) fixed center / cover`
    }
    return {
      background,
      padding: 0
    }
  }

  async onSubmit (data : { password : string, confirm : string }) {
    if (data.password !== data.confirm) {
      this.$set(this, 'validationErrors', { confirm: 'La contraseña no coincide.' })
      return
    } else {
      try {
        const { data: result } = await this.$apollo.mutate({
          mutation: gql`mutation resetPassword($token: String, $password: String) {
            result: resetPassword(token: $token, password: $password) {
              _id
              publicKey
              secretKey
              createdAt
              nonce
              lastCall
              userId
              locale
              roles
              emailVerified
              options
            }
          }`,
          variables: {
            token: this.token,
            password: data.password
          }
        })
        await this.onSuccess(result.result)
      } catch (e) {
        await this.onError(e)
      }
    }
  }

  async onSuccess (session : Session) {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Se ha cambiado exitosamente la contraseña!',
      timeout: 5000
    })
    // this.authenticating = true
    // await this.$store.dispatch('auth/loadSession', session)
    this.success = true
    // await this.$router.push(this.$route.query.to as string || '/')
  }

  async onError (e : ApolloError) {
    let message = e.message
    if (e.graphQLErrors) {
      e.graphQLErrors.forEach((err : any) => {
        if (err.error === 'validationError') {
          this.$set(this, 'validationErrors', err.validationErrors)
          this.$set(this, 'validationErrors', _mapKeys(err.validationErrors, (v, k) => k.replace(/^data\./, '')))
          return
        }
      })
      message = e.graphQLErrors.map(e => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + message,
        color: 'error',
        timeout: 10000
      })
    }
  }
}
