









import { Component, Vue, Prop } from 'vue-property-decorator'
import { FileManagerFile, FormField } from '@/models'

interface FileFieldOptions {
  fileType : string
  thumbnail : boolean
}
@Component({})
export default class FileFieldView extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value !: FileManagerFile | string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField

  /** Additional field options */
  get fieldOptions () : FileFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get isImage () {
    const type = this.value && typeof this.value !== 'string' && this.value.type || ''
    return !!type.match(/^image/)
  }

  get url () {
    if (!this.value) return ''
    if (typeof this.value === 'string') return this.value
    const { bucket, key } = this.value
    return `https://s3.amazonaws.com/${bucket}/${key}`
  }
}
