var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"subtitle-1"},[_vm._v("Series")]),_c('draggable',{staticClass:"mt-2 mb-4",attrs:{"tag":"v-expansion-panels","handle":".drag-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true,"componentData":{
      props: {
        accordion: true
      }
    }},model:{value:(_vm.series),callback:function ($$v) {_vm.series=$$v},expression:"series"}},_vm._l((_vm.series),function(item){return _c('v-expansion-panel',{key:item._id},[_c('v-expansion-panel-header',[_c('v-icon',{staticClass:"mr-2 drag-handle",staticStyle:{"flex":"0"}},[_vm._v("drag_handle")]),_c('v-icon',{staticStyle:{"flex":"0"},attrs:{"color":item.color},domProps:{"textContent":_vm._s((_vm.getDisplayType(item) || {}).icon)}}),_c('span',{staticClass:"ml-2",staticStyle:{"flex":"1"},style:({ color: item.color }),domProps:{"textContent":_vm._s(item.label || item.title)}})],1),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Nombre","color":item.color},on:{"input":_vm.update},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Título","placeholder":item.label,"color":item.color},on:{"input":_vm.update},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Tipo de gráfico","items":_vm.chartDisplayTypes,"item-text":"label","color":item.color},on:{"input":_vm.update},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.label)+" ")]}}],null,true),model:{value:(item.displayType),callback:function ($$v) {_vm.$set(item, "displayType", $$v)},expression:"item.displayType"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"outlined":"","dense":"","label":"Color","color":item.color},on:{"input":_vm.update},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{style:({ color: item.color })},[_vm._v("brightness_1")])]},proxy:true}],null,true),model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}},on))]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa","show-swatches":""},on:{"input":_vm.update},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ComponentSelect',{key:"filterId",attrs:{"name":"filterId","schema":{
                  label: 'Aplicar Filtro',
                  fieldOptions: {
                    componentTypeName: 'filters',
                    filterByKey: 'collectionId',
                    filterByValue: _vm.environmentVariables.collectionId,
                    outlined: true,
                    dense: true,
                    hideIcon: true,
                    prependInnerIcon: 'filter_list',
                    color: item.color
                  }
                },"environment-variables":_vm.environmentVariables},on:{"input":_vm.update},model:{value:(item.filterId),callback:function ($$v) {_vm.$set(item, "filterId", $$v)},expression:"item.filterId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Operación","prepend-inner-icon":"sort_by_alpha","color":item.color,"items":[
                  { value: '$avg', text: 'Promedio' },
                  { value: '$sum', text: 'Suma' },
                  { value: '$min', text: 'Mínimo' },
                  { value: '$max', text: 'Máximo' },
                  { value: '$first', text: 'Primer Valor' },
                  { value: '$last', text: 'Último Valor' },
                  { value: '$stdDevPop', text: 'Desviación estándar poblacional' },
                  { value: '$stdDevSamp', text: 'Desviación estándar muestral' } ],"hint":"NOTA: Las expresiones matamáticas no afectan el orden."},on:{"input":_vm.update},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CollectionFieldSelect',{key:"field",attrs:{"name":"field","schema":{
                  label: 'Campo',
                  fieldOptions: {
                    outlined: true,
                    dense: true,
                    icon: '',
                    prependInnerIcon: 'data_usage',
                    color: item.color,
                    allowedTypes: ['$avg','$sum','$min','$max','$stdDevPop','$stdDevSamp']
                      .includes(item.type) ?
                        ['number', 'percentage', 'currency']
                      : undefined
                  }
                },"environment-variables":_vm.environmentVariables},on:{"input":_vm.update},model:{value:(item.field),callback:function ($$v) {_vm.$set(item, "field", $$v)},expression:"item.field"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Link","prepend-inner-icon":"attach_file","hint":"Puedes usar dimensiones como :nombreCampo, o valor como :y","color":item.color},on:{"input":_vm.update},model:{value:(item.link),callback:function ($$v) {_vm.$set(item, "link", $$v)},expression:"item.link"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Transformar usando expresión matemática","prepend-inner-icon":"dialpad","hint":"Puedes usar dimensiones como (nombreCampo) o valor como (y)","color":item.color},on:{"input":_vm.update},model:{value:(item.mathOperation),callback:function ($$v) {_vm.$set(item, "mathOperation", $$v)},expression:"item.mathOperation"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Ordenar","prepend-inner-icon":"sort","color":item.color,"items":[
                  { value: '', text: 'Sin preferencia' },
                  { value: 'ASC', text: 'Ascendiente' },
                  { value: 'DESC', text: 'Descendiente' } ],"hint":"NOTA: Las expresiones matamáticas no afectan el orden."},on:{"input":_vm.update},model:{value:(item.sortType),callback:function ($$v) {_vm.$set(item, "sortType", $$v)},expression:"item.sortType"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mb-4",attrs:{"label":"Eje de referencia único","disabled":_vm.referenceAxis && _vm.referenceAxis._id !== item._id},on:{"change":_vm.update},model:{value:(item.referenceAxis),callback:function ($$v) {_vm.$set(item, "referenceAxis", $$v)},expression:"item.referenceAxis"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"red","dark":""},on:{"click":function($event){return _vm.removeSeries(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("delete")]),_vm._v(" Eliminar Serie ")],1)],1)],1)],1)],1)],1)}),1),_c('v-btn',{staticClass:"mb-4",attrs:{"block":"","outlined":"","color":"primary"},on:{"click":_vm.addSeries}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Serie ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }