







import { Component, Vue, Prop } from 'vue-property-decorator'
import { FileManagerFile, FormField } from '@/models'

interface FingerprintCapture {
  image : string
  wsq : any
}

interface FingerprintCaptureFieldOptions {
  hidden : boolean
}
@Component({})
export default class FingerprintCaptureFieldView extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value !: FingerprintCapture
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField

  get url () {
    if (!this.value || this.fieldOptions.hidden) return ''
    return this.value.image
  }

  /** Additional field options */
  get fieldOptions () : FingerprintCaptureFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }
}
