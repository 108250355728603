
































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

@Component({})
export default class ColorField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  open = false

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get normalizedColor () {
    return this.color || this.$vuetify.theme.currentTheme.primary?.toString() || '#808080'
  }

  set normalizedColor (value : string) {
    this.$emit('input', value)
  }

  get color () {
    return this.value
  }

  set color (value : string) {
    this.$emit('input', value)
  }
}
