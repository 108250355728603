import { FormSchema } from './Form'

export interface Validation {
  _id : string
  name : string
  environmentId : string
  createdAt : string
  validationTypeId : string
  options : any
}

export interface ValidationType {
  _id : string
  name : string
  optionParams : FormSchema
}

export enum ValidationMode {
  AND = 'AND',
  OR = 'OR'
}
