






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class TOTPDialog extends Vue {
  @Prop({ type: String, default: 'Autenticación de dos factores' }) title ?: string
  @Prop({ type: String, default: 'Introduce el código generado por tu aplicación' }) message ?: string
  @Prop({ type: String, default: 'Continuar' }) okButtonText ?: string
  @Prop({ type: String, default: 'vpn_key' }) okButtonIcon ?: string
  @Prop({ type: String, default: 'primary' }) okButtonColor ?: string
  @Prop({ type: String, default: 'Cancelar' }) cancelButtonText ?: string
  @Prop({ type: String, default: '' }) cancelButtonIcon ?: string
  @Prop({ type: String, default: '' }) cancelButtonColor ?: string
  @Prop({ type: String, default: '' }) placeholder ?: string
  @Prop({ type: String, default: '' }) defaultValue ?: string
  @Prop({ type: Boolean, default: false }) invalidAttempt !: boolean

  value = ''

  @Watch('defaultValue', { immediate: true })
  setDefaultValue (defaultValue : string) {
    this.value = defaultValue
  }
}
