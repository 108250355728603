



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField, FileManagerFile } from '@/models'
import CaptureModal from './CaptureModal.vue'

interface FingerprintCapture {
  image : string
  wsq : any
}

interface FingerprintCaptureFieldOptions {
  hidden : boolean
}
@Component({
  components: {
    CaptureModal
  }
})
export default class FingerprintCaptureField extends Vue {
  /** Current Value */
  @Prop({ type: Object }) value ?: FingerprintCapture
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  captureModalOpen = false

  openModal () {
    // if (this.value) return
    this.captureModalOpen = true
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : FingerprintCaptureFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue () {
    if (!this.value) return
    return 'Huella Capturada'
  }

  handleSave (data : FingerprintCapture) {
    this.$emit('input', data)
  }

  clearValue () {
    this.$emit('input', null)
  }
}
