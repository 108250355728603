







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField, FileManagerFile } from '@/models'
import UploadModal from './UploadModal.vue'
import _pick from 'lodash/pick'

interface FileFieldOptions {
  fileType : string
  thumbnail : boolean
}
@Component({
  components: {
    UploadModal
  }
})
export default class FileField extends Vue {
  /** Current Value */
  @Prop({ type: Object }) value ?: FileManagerFile
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  uploadModalOpen = false

  openModal () {
    // if (this.value) return
    this.uploadModalOpen = true
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : FileFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue () {
    if (!this.value) return
    return this.value.name
  }

  get fileIcon () {
    if (!this.value) return ''
    if (this.value.type.startsWith('image/')) return 'photo'
    if (this.value.type === 'application/pdf') return 'picture_as_pdf'
    return 'description'
  }

  handleFileSelect (file : FileManagerFile) {
    this.$emit('input', _pick(file, [
      '_id',
      'name',
      'type',
      'size',
      'key',
      'bucket',
      'status',
      'createdBy',
      'createdAt'
    ]))
  }

  clearValue () {
    this.$emit('input', null)
  }
}
