












































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import MutationForm from '@/components/form/MutationForm.vue'
import errorBg from '@/assets/error_bg.jpg'
import defaultBg from '@/assets/login_bg0.jpg'
import defaultLogo from '@/assets/icono_horizontal.svg'
import { Environment, Session } from '@/models'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'
import StatusBar from '@/components/mobileApp/StatusBar.vue'
import { alert } from '@/components/dialogs'

interface SignUpResult {
  success : boolean
  message : string
  showLoginButton : boolean
}

@Component({
  name: 'Login',
  components: {
    Loading,
    MutationForm,
    StatusBar
  },
  apollo: {
    environment: {
      query: gql`query getEnvironment($url: String) {
        environment(url: $url) {
          _id
          name
          accentColor
          useCustomCss
          customCss
          analyticsId
          allowSignUp
          logo {
            url
          }
          loginLogo {
            url
          }
          authBackgroundImage {
            url
          }
        }
      }`,
      variables () {
        return {
          url: this.domain
        }
      },
      skip () {
        return !this.domain
      }
    }
  }
})
export default class Login extends Vue {
  @Prop({ type: String, default: '' }) domain !: string
  environment : Partial<Environment> | null = null

  result : SignUpResult | null = null

  @Watch('environment')
  async setEnvironmentVariables (env : Environment) {
    if (!env) return
    const style = document.getElementById('customCSS') as HTMLStyleElement
    if (env.accentColor) {
      this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.light.secondary = this.$vuetify.theme.themes.light.accent = env.accentColor
      this.$vuetify.theme.themes.dark.primary = this.$vuetify.theme.themes.dark.secondary = this.$vuetify.theme.themes.dark.accent = env.accentColor
    }
    if (env.useCustomCss && env.customCss) {
      style.innerText = env.customCss
    } else {
      style.innerText = ''
    }
    if (env.analyticsId) {
      gtag('config', env.analyticsId)
    }
  }

  get logo () {
    if (this.$apollo.loading) return ''
    if (this.environment && this.environment.loginLogo) return this.environment.loginLogo.url
    if (this.environment && this.environment.logo) return this.environment.logo.url
    return defaultLogo
  }

  get bgStyle () {
    let background
    if (this.environment && this.environment.authBackgroundImage) {
      background = `url(${this.environment.authBackgroundImage.url}) fixed center / cover`
    } else if (!this.$apollo.loading && this.domain && !this.environment) {
      background = `url(${errorBg}) fixed center / cover`
    } else if (!this.$apollo.loading) {
      background = `url(${defaultBg}) fixed center / cover`
    }
    return {
      background,
      padding: 0
    }
  }

  mounted () {
  }

  handleResult (result : SignUpResult) {
    this.result = result
  }
}
