import * as monaco from 'monaco-editor'
import MonacoEditor from 'vue-monaco'
import { alert } from '@/components/dialogs'
import './EJSLanguage'
import './CodelessExtensions'
import './libs'

export const editorEnv = {
  environmentId: '',
  onEditComponent (componentType : string, componentId : string) {}
}

// Global Commands
monaco.editor.registerCommand('CODELESS_EDIT_COMPONENT', (ctx, componentType, componentId) => editorEnv.onEditComponent(componentType, componentId))
monaco.editor.registerCommand('CODELESS_ALERT', (ctx, message) => alert(message))

// TypeScript options
monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
  target: monaco.languages.typescript.ScriptTarget.ES2019,
  allowJs: true,
  allowNonTsExtensions: true,
  lib: ['es2019']
})


export default MonacoEditor
