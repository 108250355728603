import { ComponentType, Endpoint, EndpointType } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const endpoints = {
  name: 'endpoint',
  namespace: 'endpoints',
  title: 'Endpoints',
  titleSingle: 'Endpoint',
  description: 'Permite recibir solicitudes externas',
  icon: 'language',
  overview: 'Los endpoint son la forma de traer información desde fuera de tu proyecto. Así, si quieres traer datos desde algún API lo único que necesitas es un Endpoint, CODELESS hará el resto.',

  queryName: 'endpoints',
  queryFields: `{
    _id
    environmentId
    name
    identifier
    type
  }`,
  searchable: false,
  hidden: true,

  transformResult: (endpoint) => ({
    ...endpoint,
    title: endpoint.name,
    subtitle: endpoint.identifier,
    labels: [
      {
        list: {
          text: 'Lista',
          color: 'pink',
          textColor: 'white'
        },
        view: {
          text: 'Ver uno'
        },
        form: {
          text: 'Formulario',
          color: 'purple',
          textColor: 'white'
        }
      }[endpoint.type || EndpointType.List]
    ],
    href: `/${endpoint.environmentId}/components/endpoints/${endpoint._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Endpoint>
