import gql from 'graphql-tag'

export const FileFragment = gql`
fragment FileManagerFile on File {
  _id
  genericType
  name
  url
  type
  size
  key
  bucket
  status
  createdBy
  createdAt
}
`
