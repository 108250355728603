























import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
// @ts-ignore
} from 'tiptap-vuetify'
import Style from './extensions/style'
import { FormField } from '@/models'

interface ContentFieldOptions {
  icon ?: string
  validatePattern ?: string
  validateCaseInsensitive ?: boolean
  validateCustomError ?: string
  placeholder ?: string
}

@Component({
  components: {
    TiptapVuetify
  }
})
export default class ContentField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  tiptapExtensions = [
    History,
    Blockquote,
    Link,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [Heading, {
      options: {
        levels: [1, 2, 3]
      }
    }],
    Bold,
    Code,
    HorizontalRule,
    Paragraph,
    HardBreak,
    Style
  ]

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    // Custom validation pattern
    if (this.fieldOptions.validatePattern) {
      rules.push((v ?: string) => !v || !!v.match(new RegExp(
        this.fieldOptions.validatePattern!,
        this.fieldOptions.validateCaseInsensitive ? 'i' : ''
      )) || this.fieldOptions.validateCustomError || 'Formato inválido')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : ContentFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get currentValue () {
    return this.value
  }

  set currentValue (v : string) {
    this.$emit('input', v)
  }
}
