





























































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface SelectFieldOptions {
  placeholder ?: string
  options : {
    value : string
    label : string
  }[]
  multi ?: boolean
  disabled ?: boolean
}

@Component({})
export default class SelectField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: '' }) value !: string | string[]
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  get isDisabled () {
    return this.disabled || this.fieldOptions.disabled
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : SelectFieldOptions {
    return this.schema.fieldOptions || this.schema.options || { options: [] }
  }

  /** Input event handler */
  handleInput (...args : any[]) {
    this.$emit('input', ...args)
  }

  /** Current Value */
  get selectedValue () {
    if (!this.value) return ''
    return typeof this.value !== 'object' ? this.value : this.value[0]
  }

  set selectedValue (value : string) {
    this.$emit('input', this.fieldOptions.multi ? value[0] : value)
  }

  /** Current Values (multi) */
  get selectedValues () {
    return typeof this.value !== 'object' ? [this.value] : this.value
  }

  set selectedValues (value : string[]) {
    this.$emit('input', value)
  }
}
