import { RootState } from '@/store/index'
import { Module } from 'vuex'

export interface DashboardState {
  drawer : boolean
  designOverlay : boolean
  miniDrawer : boolean
  environmentLinkPath : string | null
}

const DashboardModule: Module<DashboardState, RootState> = {
  namespaced: true,
  state: {
    drawer: false,
    designOverlay: false,
    miniDrawer: true,
    environmentLinkPath: null
  },
  mutations: {
    setDrawer (state, payload) {
      state.drawer = payload
    },
    setDesignOverlay (state, payload) {
      state.designOverlay = payload
    },
    setMiniDrawer (state, payload) {
      state.miniDrawer = payload
    },
    setEnvironmentLinkPath (state, payload) {
      state.environmentLinkPath = payload
    }
  }
}

export default DashboardModule
