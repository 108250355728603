import { ValidationMode } from './Validation'
import { ValueType } from '../Value'

export interface Form {
  _id : string
  environmentId : string
  name : string
  title : string
  type : FormType
  collectionId : string

  serializedParams ?: FormSchema

  updateVariableName : string
  hideEmpty : string
  emptyFormText : string

  fields : FormField[]
  fieldsList ?: FormField[] // Again, used inconsistently for some forms
  onSuccessViewPath : string

  onSuccessExternalTab ?: boolean
  reset : boolean

  afterHooksIds : string[]
  shouldStopHooksOnError : boolean
  shouldDeleteItemOnError : boolean

  validationsIds : string[]
  validationsMode : ValidationMode

  submitButtonText : string
  submitButtonIcon : string
  submitButtonColor : string
  submitButtonBgColor : string

  resetButtonText : string
  buttonAlignment : string
  requireTwoFactor : boolean

  roles : string[]
  onSuccessEnvironmentVariables : string[]
  postItemToUrl : string
}

export interface FormField {
  fieldName : string
  type : string
  formFieldType : ValueType

  optional : boolean
  fixed : {
    value : any
  }

  label ?: string
  description ?: string
  placeholder ?: string
  options ?: Record<string, any> // Apparently used instead of fieldOptions for some fields, idk why
  defaultValue ?: any
  fieldOptions ?: any
  fieldType ?: string

  showField: boolean
  parameterName : string
  editableLabel : string
  requiredField : string
  requiredValue : string
  requiredParameter : string
  requiredType : string
  indicatorId : string
  editableDefaultValue : string
  indicatorDefaultValue : string

  sizeSmall : string
  sizeMedium : string
  sizeLarge : string

  __graphQLType ?: string
}

export enum FormType {
  Create = 'create',
  Update = 'update'
}

/**
 * Represents an entire form.
 */
export interface FormSchema {
  [key : string] : FormField
}
