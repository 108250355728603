export interface Link {
  _id : string
  environmentId : string
  title : string
  type ?: LinkType
  icon ?: string
  path ?: string
  fields ?: LinkField[]
  createdAt : Date
  roles : string[]
  position : number
  showInHome ?: boolean
  sizeSmall ?: string
  sizeMedium ?: string
  sizeLarge ?: string
  textColor ?: string
  backgroundColor ?: string
}

/**
 * Sub-link
 */
export interface LinkField {
  title : string
  path : string
  icon ?: string
  showInHome ?: boolean
  sizeSmall ?: string
  sizeMedium ?: string
  sizeLarge ?: string
  roles : string[]
  textColor ?: string
  backgroundColor ?: string
}

/**
 * Link Type
 */
export enum LinkType {
  Single = 'path',
  Category = 'category',
  UserMenu = 'userMenu'
}
