import { ComponentType, Button } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const buttons = {
  name: 'button',
  namespace: 'buttons',
  title: 'Botones',
  titleSingle: 'Botón',
  description: 'Ejecuta acciones',
  icon: 'more',
  color: 'orange',
  overview: 'Los botones son elementos muy comunes en plataformas y aplicaciones de softwares. Los usamos para desencadenar eventos dentro de nuestros formularios o aplicaciones. Con CODELESS puedes crear tus propios botones para disparar eventos dentro de tu proyecto.',

  queryName: 'buttons',
  queryFields: `{
    _id
    name
    title
  }`,
  searchable: true,

  transformResult: (button) => ({
    ...button,
    name: button.name,
    title: button.title,
    subtitle: button.name,
    href: `buttons/${button._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  view: () => asyncImport(import('./View.vue'))
} as ComponentType<Button>
