



import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class FieldView extends Vue {
  @Prop({ default: '' }) value !: any
}
