import WebPushProvider from './web'
import FirebasePushProvider from './firebase'

let currentProvider : PushNotificationProvider | null = null

export default function getPushProvider () {
  if (!currentProvider) {
    if (window.mobileApp) {
      currentProvider = new FirebasePushProvider()
    } else {
      currentProvider = new WebPushProvider()
    }
  }
  return currentProvider
}

/** Base interface for push notification providers */
export interface PushNotificationProvider {
  /** Queries the current state for push notifications */
  getState : () => Promise<{ available : boolean, enabled : boolean }>
  /** Subscribe to push notifications */
  subscribe : () => Promise<any>
  /** Unsubscribe from push notifications */
  unsubscribe : () => Promise<any>
}
