


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import numeral from '@/plugins/numeral'

interface NumberFieldOptions {
  min ?: number
  max ?: number
  integer ?: boolean
  placeholder ?: string
}

@Component({})
export default class NumberField extends Vue {
  /** Current Value */
  @Prop({ type: Number }) value ?: number | null
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  get displayValue () {
    if (this.value == null) return ''
    return numeral(this.value).format('$0,0.[00]')
  }

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Number validation
    rules.push((v ?: number) => !v || !isNaN(numeral(v).value()) || 'Valor debe ser numérico')
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: number | null) => !!v || 'Campo Requerido.')
    }
    // Max number validation
    if (this.fieldOptions.max) {
      rules.push((v ?: number) => numeral(v).value() <= this.fieldOptions.max! || `Valor excede ${this.fieldOptions.max}.`)
    }
    // Min number validation
    if (this.fieldOptions.min) {
      rules.push((v ?: number) => numeral(v).value() >= this.fieldOptions.min! || `Valor es inferior a ${this.fieldOptions.min}.`)
    }
    // Integer validation
    if (this.fieldOptions.integer) {
      rules.push((v ?: number) => numeral(v).value() % 1 === 0 || 'Valor debe ser entero.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () : NumberFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput (value : string) {
    this.$emit('input', value !== '' ? numeral(value).value() : undefined)
  }
}
