































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CodelessLoading extends Vue {
  @Prop({ type: String, default: '' }) text !: string
}
