import { render, staticRenderFns } from "./View.vue?vue&type=template&id=4ccadede&scoped=true&"
import script from "./View.vue?vue&type=script&lang=ts&"
export * from "./View.vue?vue&type=script&lang=ts&"
import style0 from "./View.vue?vue&type=style&index=0&id=4ccadede&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccadede",
  null
  
)

export default component.exports