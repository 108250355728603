









































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { PaginatedQueryResult, FileManagerFile } from '@/models'
import { FileFragment } from './fragments'
import gql from 'graphql-tag'
import moment from '@/plugins/moment'
import Loading from '@/components/Loading.vue'
import mime from 'mime-types'
import axios from 'axios'
import _deburr from 'lodash/deburr'

@Component({
  components: {
    Loading
  },
  apollo: {
    files: {
      query: gql`query getFiles ($page : BigInt, $limit : BigInt, $filter : String) {
        files: fileManagerFiles(page: $page, limit: $limit, filter: $filter) {
          totalCount
          totalPages
          items {
            ...FileManagerFile
          }
        }
      }
      ${FileFragment}
      `,
      variables () {
        return {
          page: 1,
          limit: 100,
          filter: this.searchQuery
        }
      },
      skip () {
        return !this.browse
      }
    }
  },
  filters: {
    formatDate (input : string) {
      return moment(input).format('MM-DD-YYYY HH:mm')
    }
  }
})
export default class FileUploadModal extends Vue {
  @Prop({ type: Boolean, required: true }) open !: boolean
  @Prop({ type: String, default: '' }) fileType !: string
  @Prop({ type: Boolean, default: false }) inputPhoto !: boolean

  browse = false
  uploading = false
  hoveringFile = false
  uploadFilename = ''
  uploadProgress = ''
  uploadProgressNumeric = 0
  files : PaginatedQueryResult<FileManagerFile> | null = null
  searchQuery = ''

  get dragAvailable () {
    return !window.mobileApp
  }

  get availableFiles () {
    if (!this.files) return []
    return this.files.items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  }

  get modalOpen () {
    return this.open
  }

  get accept () {
    if (!this.fileType) return
    if (this.inputPhoto) return 'image/*'
    if (this.fileType.indexOf('/') < 0 && this.fileType.indexOf('.') < 0) {
      return `${this.fileType}/*`
    }
    return this.fileType
  }

  set modalOpen (v : boolean) {
    this.$emit('update:open', v)
    if (!v) {
      this.browse = false
      this.uploading = false
      this.uploadProgress = ''
      this.uploadProgressNumeric = 0
      this.uploadFilename = ''
      this.searchQuery = ''
    }
  }

  uploadFile () {
    const fileEl = this.$refs.file as HTMLInputElement
    fileEl.click()
  }

  selectFile (file : FileManagerFile) {
    this.$emit('select', file)
    this.modalOpen = false
  }

  async processFileDrop (e : DragEvent) {
    if (this.uploading) return
    e.preventDefault()
    this.hoveringFile = false
    const file = e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]
    try {
      if (file) {
        this.uploading = true
        await this.performFileUpload(file)
      }
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 5000
      })
    } finally {
      this.uploadFilename = ''
      this.uploadProgress = ''
      this.uploadProgressNumeric = 0
      this.uploading = false
    }
  }

  async processFileInput () {
    if (this.uploading) return
    this.uploading = true
    try {
      const fileEl = this.$refs.file as HTMLInputElement
      const file = fileEl.files && fileEl.files[0]
      if (file) {
        await this.performFileUpload(file)
      }
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 5000
      })
    } finally {
      this.uploadFilename = ''
      this.uploadProgress = ''
      this.uploadProgressNumeric = 0
      this.uploading = false
    }
  }

  async performFileUpload (file : File) {
    this.uploadFilename = _deburr(file.name.toLowerCase().replace(/[^\w\d]/g, ''))
    if (this.fileType && file.type.indexOf(this.fileType) !== 0) {
      throw new Error('Tipo de archivo no permitido para este campo.')
    }
    this.uploadProgress = 'Obteniendo metadatos...'
    // Get upload credentials
    const { data: credentials } = await this.$apollo.mutate({
      mutation: gql`mutation generateUploadCredentials($name: String, $size: Float, $type: String) {
        result: generateUploadCredentials(name: $name, size: $size, type: $type) {
          fileId
          url
          fields
          key
        }
      }`,
      variables: {
        name: file.name,
        size: file.size,
        type: file.type || mime.lookup(file.name) || 'application/octet-stream'
      }
    })
    if (!credentials.result) throw new Error('Error al obtener metadatos.')
    const destination = credentials.result
    // Upload File
    const { data: upload } = await axios({
      url: destination.url,
      method: 'post',
      transformRequest: [
        data => {
          const formData = new FormData()
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              formData.append(key, data[key])
            }
          }
          return formData
        }
      ],
      data: {
        ...destination.fields,
        file
      },
      onUploadProgress: (progress) => {
        this.uploadProgressNumeric = (progress.loaded / progress.total) * 100
        this.uploadProgress = `${Math.round(this.uploadProgressNumeric)}%`
      }
    })
    // Complete Upload
    this.uploadProgress = 'Procesando Archivo...'
    this.uploadProgressNumeric = 0
    const { data: result } = await this.$apollo.mutate({
      mutation: gql`mutation completeUpload($fileId: ID) {
        uploadedFile: completeUpload(fileId: $fileId) {
          ...FileManagerFile
        }
      }
      ${FileFragment}`,
      variables: {
        fileId: destination.fileId
      }
    })
    await this.$apollo.queries.files.refetch()
    if (!this.browse) {
      this.$emit('select', result.uploadedFile)
      this.modalOpen = false
    }
  }

  isValidFile (file : FileManagerFile) {
    if (!this.fileType) return true
    return file.type.indexOf(this.fileType) === 0
  }
}
