import { ComponentType, Collection } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const collections = {
  name: 'collection',
  namespace: 'collections',
  title: 'Colecciones',
  titleSingle: 'Colección',
  description: 'Almacén de base de datos',
  icon: 'storage',

  queryName: 'collections',
  queryFields: `{
    _id
    environmentId
    name
  }`,
  searchable: true,
  hidden: true,

  transformResult: (collection) => ({
    ...collection,
    name: collection.name,
    title: collection.name,
    subtitle: collection._id,
    href: `/${collection.environmentId}/collections/${collection._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Collection>
