import { ComponentType, EnvironmentUser } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const environmentUsers = {
  name: 'environmentUser',
  namespace: 'environmentUsers',
  title: 'Usuarios',
  titleSingle: 'Usuario',
  description: 'Usuarios con acceso un Entorno',
  icon: 'person',
  overview: 'Los usuarios representan a las personas que tendrán acceso a una determinada sección de un ambiente. A cada usuario se le puede asignar uno o varios roles así como permisos especiales dentro del entorno.',

  queryName: 'environmentUsers',
  queryFields: `{
    _id
    environmentId
    email
    resolvedRoles {
      _id
      name
    }
  }`,
  searchable: true,
  hidden: true,

  transformResult: (envUser) => ({
    ...envUser,
    title: envUser.email,
    subtitle:
      ' ' +
      (envUser.resolvedRoles?.map(r => r.name).slice(0, 3).join(' | ') || '') +
      (envUser.resolvedRoles && envUser.resolvedRoles.length > 3 ? ` | ...` : ''),
    labels: [
      {
        text: envUser.resolvedRoles?.length
          ? envUser.resolvedRoles.length + (envUser.resolvedRoles.length === 1 ? ' Rol' : ' Roles')
          : 'Sin Roles',
        color: envUser.resolvedRoles?.length ? 'primary' : ''
      }
    ],
    href: `/${envUser.environmentId}/components/environmentUsers/${envUser._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<EnvironmentUser>
