import { CollectionData, ComponentType, FormField, Table, TableFieldType } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const tables = {
  name: 'table',
  namespace: 'tables',
  title: 'Tablas',
  titleSingle: 'Tabla',
  description: 'Muestra registros de una colección',
  icon: 'border_all',
  color: 'pink',
  overview: 'Las tablas son la mejor forma de mostrar la información de sus colecciones. Si ya tiene colecciones de datos ahora cree sus tablas para mostrarlas en su proyecto CODELESS.',

  queryName: 'tables',
  queryFields: `{
    _id
    name
    title
  }`,
  searchable: true,

  transformResult: (table) => ({
    ...table,
    name: table.name,
    title: table.title,
    subtitle: table.name,
    href: `tables/${table._id}`
  }),

  viewSkeleton: 'table',

  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Table>

export interface ViewTableRow {
  id: string
  raw: CollectionData,
  cols: ViewTableCell[]
}

export interface ViewTableCell {
  key: string,
  type: TableFieldType,
  options?: Record<string, any>,
  width?: number,
  class?: number,
  field?: FormField,
  data: any
}
