


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import fieldTypes from '@/components/fields'

@Component({})
export default class KeyValueList extends Vue {
  @Prop({ type: [Object, Array], default: () => ({}) }) value !: Record<string, string>
  @Prop({ type: Boolean, default: false }) array !: boolean

  @Prop({ type: String, default: 'key' }) keyField !: string
  @Prop({ type: String, default: 'value' }) valueField !: string

  @Prop({ type: String, default: 'Nombre' }) keyLabel !: string
  @Prop({ type: String, default: 'Valor' }) valueLabel !: string

  items : Record<string, string>[] = []

  @Watch('value', { immediate: true })
  updateItems () {
    console.log(this.value)
    if (this.array) {
      this.items = this.value && this.value.length ? this.value as unknown as Record<string, string>[] : []
    } else {
      this.items = Object.keys(this.value || {}).map(key => ({
        [this.keyField]: key, value: this.value[key]
      }))
    }
  }

  emitValue () {
    if (this.array) {
      this.$emit('input', this.items)
    } else {
      const obj : Record<string, string> = {}
      this.items.forEach(i => {
        obj[i[this.keyField]] = i[this.valueField]
      })
      this.$emit('input', obj)
    }
  }

  deleteItem (key : string) {
    this.items.splice(this.items.findIndex(i => i[this.keyField] === key), 1)
    this.emitValue()
  }

  addItem () {
    this.items.push({ [this.keyField]: `item${this.items.length + 1}`, [this.valueField]: '' })
    this.emitValue()
  }
}
