import { RootState } from '@/store/index'
import { Module } from 'vuex'
import { Dialog } from '@/models/Dialogs'

export interface DialogsState {
  openDialogs : Dialog[]
}

const defaults = {
  openDialogs: []
}

/**
 * Snackbar module - state of the global snackbar.
 */
const SnackbarModule : Module<DialogsState, RootState> = {
  namespaced: true,
  state: {
    ...defaults
  },
  mutations: {
    addDialog (state, dialog : Dialog) {
      if (!dialog.id) throw new Error('Dialog has no ID')
      if (!dialog.component) throw new Error('Dialog has no component')
      if (state.openDialogs.find(d => d.id === dialog.id)) {
        throw new Error('Dialog is already open')
      }
      state.openDialogs.push(dialog)
    },
    showDialog (state, dialogId : string) {
      const dialogIx = state.openDialogs.findIndex(d => d.id === dialogId)
      if (dialogIx < 0) throw new Error('Dialog not found')
      state.openDialogs[dialogIx].open = true
    },
    resolveDialog (state, dialogId : string) {
      const dialogIx = state.openDialogs.findIndex(d => d.id === dialogId)
      if (dialogIx < 0) throw new Error('Dialog not found')
      state.openDialogs[dialogIx].resolved = true
    },
    hideDialog (state, dialogId : string) {
      const dialogIx = state.openDialogs.findIndex(d => d.id === dialogId)
      if (dialogIx < 0) throw new Error('Dialog not found')
      state.openDialogs[dialogIx].open = false
    },
    removeDialog (state, dialogId : string) {
      const dialogIx = state.openDialogs.findIndex(d => d.id === dialogId)
      if (dialogIx < 0) throw new Error('Dialog not found')
      state.openDialogs.splice(dialogIx, 1)
    },
    resetDialogs (state) {
      state.openDialogs.splice(0, state.openDialogs.length)
    }
  }
}

export default SnackbarModule
