















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FormSchema } from '@/models'
import Loading from '@/components/Loading.vue'
import Fields from './Fields.vue'
import _pick from 'lodash/pick'
import { ValueType } from '@/models/Value'

@Component({
  components: {
    Fields,
    Loading
  }
})
export default class Form extends Vue {
  /** Form Schema */
  @Prop({ type: Object, required: true }) schema !: FormSchema
  /** View Params */
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables ?: Record<string, string>

  /** Save Button Text */
  @Prop({ type: String, default: 'Enviar' }) submitButtonText !: string
  /** Save Button Text */
  @Prop({ type: String, default: null }) formType !: string
  /** Save Button Icon */
  @Prop({ type: String, default: 'send' }) submitButtonIcon !: string
  /** Save Button Color */
  @Prop({ type: String, default: 'primary' }) submitButtonColor !: string
  /** Button alignment */
  @Prop({ type: String, default: 'right' }) alignButtons !: string
  /** Disable (hide) the reset button */
  @Prop({ type: Boolean, default: false }) noReset !: boolean
  /** Busy state */
  @Prop({ type: Boolean }) busy !: boolean
  /** Disabled state */
  @Prop({ type: Boolean }) disabled !: boolean

  /** Saved values */
  @Prop({ type: Object, default: () => ({}) }) savedValue !: Record<string, any>
  /** External Validation Errors */
  @Prop({ type: Object, default: () => ({}) }) validationErrors !: Record<string, string[]>

  /** Force Mobile Sizes */
  @Prop({ type: Boolean, default: false }) forceMobile !: boolean

  /** Modified values */
  pendingValue : Record<string, any> = {}

  /** Is the form valid? */
  valid = false

  get paramValues () {
    const res : Record<string, any> = {}
    Object.keys(this.schema).filter(f => this.schema[f].formFieldType === ValueType.Parameter).forEach(f => {
      const param = this.schema[f].parameterName
      res[f] = this.viewParams[param]
    })
    return res
  }

  get justifyButtons () {
    if (this.alignButtons === 'left') return 'start'
    if (this.alignButtons === 'right') return 'end'
    return this.alignButtons
  }

  @Watch('savedValue')
  resetPendingValue () {
    // if(this.formType === 'create'){
      this.pendingValue = {}
    // }
  }

  /** Current value (saved + pending). */
  get value () {
    return {
      ...this.savedValue,
      ...this.paramValues,
      ...this.pendingValue
    }
  }

  set value (v : Record<string, any>) {
    this.pendingValue = v
  }

  /** Has the form been modified? */
  get dirty () {
    return Object.keys(this.pendingValue).length > 0
  }

  async submit () {
    if (this.busy) return
    if (!(this.$refs.fields as any).validate()) return
    this.$emit('submit', this.value)
  }

  get visibleFields () {
    return _pick(this.schema, Object.keys(this.schema).filter(f => {
      if (!this.schema[f].formFieldType) return true
      if ([ValueType.Editable, ValueType.Section].indexOf(this.schema[f].formFieldType) < 0) return false
      if (!this.schema[f].requiredType) return true
      if (this.schema[f].requiredType === 'editable') {
        const { requiredField, requiredValue, showField } = this.schema[f]
        if (showField) {
          return this.value[requiredField]?.toString() === requiredValue
        } else {
          return this.value[requiredField]?.toString() !== requiredValue
        }
      } else {
        const { requiredField, requiredParameter, showField } = this.schema[f]
        if (showField) {
          return this.value[requiredField] === this.viewParams[requiredParameter]
        } else {
          return this.value[requiredField] !== this.viewParams[requiredParameter]
        }
      }
    }))
  }

  /** Revert pending changes */
  undo () {
    this.$set(this, 'pendingValue', {})
    this.$nextTick(() => (this.$refs.fields as any).resetValidation())
  }
}
