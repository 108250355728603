//@ts-ignore
import { Mark } from 'tiptap'
//@ts-ignore
import { updateMark, removeMark, pasteRule } from 'tiptap-commands'
//@ts-ignore
import { getMarkAttrs } from 'tiptap-utils'

import { showDialogPromise } from '@/components/dialogs'
import Vue from 'vue'
import { VNode } from 'vue/types/umd'

import Chance from 'chance'
const chance = new Chance()

//@ts-ignore
import { TiptapVuetify } from 'tiptap-vuetify'

// VERY dirty hack
TiptapVuetify
  .options
  .components
  .Toolbar
    .options
    .components
    .ActionsRender
      .options
      .methods
      .isBtn = () => true
// Yet *another* VERY dirty hack (I *really* hate this library)
TiptapVuetify
.options
.components
.Toolbar
  .options
  .components
  .ActionsRender
    .options
    .components
    ['action-btn']
      .options
      .computed
      .isVuetifyIcon
      .get = () => true

interface StyleNode {
  textColor : string
}

function css (properties : Record<string, any>) {
  let resultStr = ''
  for (const prop in properties) {
    if (!properties[prop]) continue
    resultStr += `${prop}:${properties[prop]};`
  }
  return resultStr
}

class Style extends Mark {
  get name () {
    return 'style'
  }

  get defaultOptions () {
    return {
      textColor: true
    }
  }

  get schema () {
    return {
      attrs: {
        textColor: {
          default: ''
        }
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'span[style]',
          getAttrs: (dom : HTMLElement) => ({
            textColor: dom.style.color
          })
        }
      ],
      toDOM: (n : any) => {
        console.log(n)
        return ['span', {
        style: css({
          color: n.attrs.textColor.replace(/FF$/, ''),
          opacity: n.attrs.textColor && parseInt(n.attrs.textColor.slice(7, 9), 16)
        })
      }, 0]
    }
    }
  }

  commands ({ type } : any) {
    return (attrs : StyleNode) => {
      if (attrs.textColor) {
        return updateMark(type, attrs)
      }
      return removeMark(type)
    }
  }
}

export default class StyleExtensionVuetify {
  nativeExtensionInstance = new Style()

  get availableActions () : any[] {
    return [
      {
        render: {
          options: {
            tooltip: () => 'Color de Texto',
            icons: {
              md: 'format_color_fill'
            },
            nativeExtensionName: 'style',
            async onClick ({ context } : any) {
              let targetColor = context.getMarkAttrs('style').textColor || ''
              // Show color picker
              var result = await showDialogPromise({
                id: 'colorPicker-' + chance.guid(),
                maxWidth: 320,
                component: Vue.extend({
                  render (h) : VNode {
                    return (
                      <v-card>
                        <v-card-title>Color de texto</v-card-title>
                        <v-card-text>
                        <v-color-picker
                          mode="hexa"
                          value={targetColor}
                          onInput={(value : string) => targetColor = value}
                          show-swatches
                        />
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn
                            text
                            color="primary"
                            onClick={() => this.$emit('close')}
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            onClick={() => this.$emit('result', targetColor)}
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    )
                  }
                })
              })

              if (result !== false) {
                context.commands.style({
                  textColor: result
                })
              }
            },
            isActive ({ isActive } : any) {
              return isActive.style && isActive.style()
            }
          }
        }
      }
    ]
  }
}
