import Vue from 'vue'
import Vuetify from 'vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/src/locale/es'
import colors from 'vuetify/lib/util/colors'
// @ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#808080',
        secondary: '#C6C6C6',
        accent: '#808080'
      },
      dark: {
        primary: '#808080',
        secondary: '#C6C6C6',
        accent: '#E5E5E5'
      }
    },
    options: {
      customProperties: true
    }
  },
  options: {
    customProperties: true
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md'
})

export default vuetify
