



import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from '@/plugins/moment'

@Component({})
export default class DateTimeFieldView extends Vue {
  @Prop({ type: String }) value !: string

  get displayValue () {
    return moment(this.value).format('DD-MM-YYYY HH:mm')
  }
}
