export interface Endpoint {
  _id : string
  environmentId : string
  identifier : string
  name : string
  createdAt : Date
  type : EndpointType
  formId ?: string
  collectionId ?: string
  filterId ?: string
  requireToken ?: boolean
  tokens ?: string[]
}

export enum EndpointType {
  List = 'list',
  View = 'view',
  Form = 'form'
}
