var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.schema.newDesign === true)?[_c('label',{staticStyle:{"color":"#8a8a8a","font-size":"14px"}},[_vm._v(_vm._s(this.schema.label || this.schema.editableLabel || this.name)+" ")]),(false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("help ")])]}}],null,false,3383618807)},[_c('span',[_vm._v("Nombre de la tabla")])]):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"disabled":_vm.disabled,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"new-field-color"},[_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"name":_vm.name,"hint":_vm.schema.description,"required":!_vm.schema.optional,"placeholder":_vm.fieldOptions.placeholder,"rules":_vm.validationRules,"error-messages":_vm.errorMessages,"disabled":_vm.disabled,"clearable":"","outlined":"","dense":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{style:({ color: _vm.value })},[_vm._v("brightness_1")])]},proxy:true}],null,true),model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},'v-text-field',_vm.fieldOptions,false),on))],1)]}}],null,false,4239220575),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-color-picker',{attrs:{"mode":"hexa","show-swatches":""},model:{value:(_vm.normalizedColor),callback:function ($$v) {_vm.normalizedColor=$$v},expression:"normalizedColor"}})],1)]:[_c('v-menu',{ref:"menu",attrs:{"disabled":_vm.disabled,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"name":_vm.name,"label":_vm.schema.label || _vm.schema.editableLabel || _vm.name,"hint":_vm.schema.description,"required":!_vm.schema.optional,"placeholder":_vm.fieldOptions.placeholder,"rules":_vm.validationRules,"error-messages":_vm.errorMessages,"disabled":_vm.disabled,"clearable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{style:({ color: _vm.value })},[_vm._v("brightness_1")])]},proxy:true}],null,true),model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},'v-text-field',_vm.fieldOptions,false),on))]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-color-picker',{attrs:{"mode":"hexa","show-swatches":""},model:{value:(_vm.normalizedColor),callback:function ($$v) {_vm.normalizedColor=$$v},expression:"normalizedColor"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }