import { Role } from './Role'


export interface View {
  _id : string
  environmentId : string
  title : string
  path : string
  name : string
  titleColor : string
  titleBackground : string
  showBorders : boolean
  viewRoles ?: Role[]
  backButton : boolean
  printButton : boolean
  backLink : string
  items : ViewItem[]
  previewParameters : Record<string, string>
  useCustomCss : boolean
  customCss ?: string
}

export interface ViewItem {
  sizeSmall : string
  sizeMedium : string
  sizeLarge : string
  helpBlockId ?: string
  titleColor ?: string
  titleBackground ?: string
  hideBorders ?: boolean
  hidePrint ?: boolean
  cssClass ?: string
  type : string
  formId ?: string
  tableId ?: string
  indicatorId ?: string
  chartId ?: string
  buttonId ?: string
  blockId ?: string
  fullSize ?: string
  subItems ?: ViewItem[]
  content ?: string
  forceBorders ?: boolean
  viewType ?: ViewTypes
  tabName ?: string
  touchless ?: boolean
}

export enum ViewTypes {
  Default = 'default',
  Tabs = 'tabs'
}

export enum ViewItemType {
  Form = 'form',
  Table = 'table',
  Indicator = 'indicator',
  Chart = 'chart',
  Button = 'button',
  ContentBlock = 'block',
  Section = 'section',
  Layout = 'layout',
  Tabs = 'tabs'
}
