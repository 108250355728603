import Vue from 'vue'
import Loading from '@/components/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'

/**
 * Imports a component asynchronously and displays the default Loading animation.
 */
export default function asyncImport (importComp : Promise<{ default: Vue.Component | Vue.VueConstructor }>) {
  return {
    component: importComp.then(e => e.default),
    loading: Loading,
    error: ComponentError
  } as unknown as Vue.AsyncComponent
}
