














import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

@Component({})
export default class BooleanField extends Vue {
  /** Current Value */
  @Prop({ type: Boolean, default: '' }) value !: string
  /** Validation Errors */
  @Prop() errorMessages !: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name !: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema !: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled !: boolean

  /** Validation Rules */
  get validationRules () {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v ?: boolean) => v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions () {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get checked () {
    return !!this.value
  }

  set checked (value : boolean) {
    this.$emit('input', value)
  }
}
