






































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { alert } from '@/components/dialogs'
import { Debounce } from '@/utils/decorators'
import axios from 'axios'

const osm = axios.create({
  baseURL: 'https://nominatim.openstreetmap.org/'
})

@Component({
  components: {
    Loading
  }
})
export default class LocationSelectModal extends Vue {

  @Prop({ type: Boolean, required: true }) open !: boolean
  @Prop({ type: String, default: '' }) value !: string
  @Prop({ type: Boolean, default: false }) gpsOnly !: boolean
  @Prop({ type: Boolean, default: false }) viewOnly !: boolean
  @Prop({ type: Boolean, default: false }) isLoading !: boolean

  zoom = 5
  center = [-70.65, -33.45]
  rotation = 0
  selected : number[] = []
  geolocationWorking = false
  geolocationAskPermission = false
  searchLoading = false
  searchResults = []
  searchInput = ''

  mounted () {
    if (this.gpsOnly) {
      this.selectCurrentLocationGPS(true)
    }
  }

  get textCoordinates () {
    return this.selected.join(',') || '0,0'
  }

  get coordinates () {
    // if (!this.selected || this.selected.length <= 0) return ''
    return JSON.stringify(this.selected)
  }

  get modalOpen () {
    return this.open
  }

  set modalOpen (v : boolean) {
    this.$emit('update:open', v)
    if (!v) {
      this.loadValue()
      this.searchInput = ''
      this.searchResults = []
      this.zoom = 5
      this.center = [-70.65, -33.45]
      this.rotation = 0
    }
  }

  @Watch('value', { immediate: true })
  loadValue () {
    this.selected = (this.value || '').split(',').map(c => parseFloat(c)).filter(c => c || c === 0)
    if (this.selected.length >= 2) {
      this.center = this.selected
      this.zoom = 16
    }
  }

  @Watch('searchInput')
  @Debounce(2000)
  async fetchSearchResults () {
    if (this.searchInput.length < 2) {
      this.searchResults = []
      return this.searchLoading = false
    }

    const { data: results } = await osm.get(`search?format=json&dedupe=1&q=${encodeURIComponent(this.searchInput)}`)

    this.searchResults = results.map((r : any) => ({
      text: r.display_name,
      value: [r.lon, r.lat].join(',')
    }))

    this.searchLoading = false
  }

  @Watch('searchInput')
  async setSearchLoading () {
    this.searchLoading = this.searchInput && this.searchInput.length >= 2 || false
  }

  save () {
    this.$emit('input', this.selected.join(','))
    this.modalOpen = false
  }

  handleMapClick (e : any) {
    if (!e.coordinate || this.gpsOnly) return
    this.selectCoordinate(e.coordinate)
  }

  selectCoordinate (coordinate : number[]) {
    this.selected = coordinate || []
    if (!coordinate || coordinate.length < 2) return
    setTimeout(() => {
      const view = this.$refs.mapView as any
      if (view) {
        view.animate({
          center: coordinate,
          zoom: 16,
          duration: 600
        })
      } else {
        this.center = coordinate
      }
    }, 400)
  }

  selectTextCoordinate (coordinate : string) {
    this.selectCoordinate(coordinate.split(',').map(c => parseFloat(c)).filter(c => c || c === 0))
  }

  async selectCurrentLocationGPS (auto = false) {
    this.geolocationWorking = true
    this.$emit('update:isLoading', true)

    if (navigator.permissions) {
      const permission = await navigator.permissions.query({ name: 'geolocation' })

      if (permission.state === 'prompt') {
        if (auto) return
        this.geolocationAskPermission = true
      }

      if (permission.state === 'denied') {
        if (auto) return
        this.geolocationWorking = false
        this.geolocationAskPermission = false
        return alert(window.mobileApp ?
          'Esta función requiere permisos de ubicación.\nRevisa la configuración de permisos de la app en el sistema.'
        : 'Esta función requiere permisos de ubicación.\nRevisa la configuración de permisos del navegador.')
      }
    }

    navigator.geolocation.getCurrentPosition((location) => {
      this.geolocationWorking = false
      this.geolocationAskPermission = false
      this.$emit('update:isLoading', false)
      this.selectCoordinate([location.coords.longitude, location.coords.latitude])
      if (auto) this.save()
    }, (e) => {
      this.geolocationWorking = false
      this.geolocationAskPermission = false
      this.$emit('update:isLoading', false)
      console.error('Error al obtener ubicación:', e)
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error al obtener la ubicación actual',
        color: 'error'
      })
    }, { enableHighAccuracy: true })
  }
}
